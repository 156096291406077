import './SearchInput.scss';
import React, {useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {SearchInputModel, SearchRequest, SuggestItem} from '@features/search/interfaces';
import {useSearchHistory} from '@features/search/hooks';
import {SearchBar} from '@features/search';
import {getHomeUrl, getSearchUrl} from '@utils';

const b = block('header-search-input');

export const SearchInput: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [searchText, setSearchText] = useState<SearchInputModel>({
        originalValue: searchParams.get('text') || '',
        cleanedValue: searchParams.get('text') || '',
        needToSuggestBrands: false
    });
    const [searchBrand, setSearchBrand] = useState(decodeURIComponent(searchParams.get('brand') || ''));
    const [useAnalogs, setUseAnalogs] = useState(!(searchParams.get('useAnalogs') === 'false'));
    const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);
    const [searchHistory] = useSearchHistory();

    const navigateTo = useNavigate();

    useEffect(() => {
        setSearchBrand(decodeURIComponent(searchParams.get('brand') || ''));
        setSearchText({
            originalValue: searchParams.get('text') || '',
            cleanedValue: searchParams.get('text') || '',
            needToSuggestBrands: false
        });
        setUseAnalogs(!(searchParams.get('useAnalogs') === 'false'));
    }, [searchParams]);

    function handleInputChange(value: SearchInputModel) {
        setSearchBrand('');
        setSearchText(value);
        if (searchText.cleanedValue === value.cleanedValue) return;
        setSuggestItems([]);
    }

    function handleSearchClick() {
        if (searchText?.cleanedValue && suggestItems.length === 1) {
            navigateTo(getSearchUrl(searchText.cleanedValue, suggestItems[0].name, useAnalogs));
            return;
        }
        if (searchText?.cleanedValue) {
            navigateTo(getHomeUrl(searchText.cleanedValue, useAnalogs));
            return;
        }
    }

    function handleBrandSelected(brand: SuggestItem) {
        if (searchText?.cleanedValue) {
            navigateTo(getSearchUrl(searchText.cleanedValue, brand.name, useAnalogs));
            setSearchBrand(brand.name);
        }
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        navigateTo(getSearchUrl(searchHistory.article, searchHistory.brand, useAnalogs));
        setSearchText({
            originalValue: searchHistory.article,
            cleanedValue: searchHistory.article,
            needToSuggestBrands: false
        });
        setSearchBrand(searchHistory.brand);
    }

    return (
        <div className={b()}>
            <SearchBar
                size="s"
                searchText={searchText}
                searchBrand={searchBrand}
                useAnalogs={useAnalogs}
                onSearchClick={handleSearchClick}
                onInputChange={handleInputChange}
                onBrandSelected={handleBrandSelected}
                onBrandSuggestionsChanged={(items) => setSuggestItems(items)}
                onUseAnalogsChanged={(value) => setUseAnalogs(value)}
                searchHistory={searchHistory}
                onSearchHistorySelected={handleSearchHistorySelected}
            />
        </div>
    );
};
