import React from 'react';
import {Outlet} from 'react-router-dom';
import {Text} from '@gravity-ui/uikit';
import {SideBar} from '@features/profile';
import block from 'bem-cn-lite';

import './ProfilePage.scss';

const b = block('profile-page');

export const ProfilePage: React.FC = () => {

    return (
            <div className={b()}>
                <Text className={b('header')} variant="display-2">Мой профиль</Text>
                <SideBar />
                <div className={b('content')}>
                    <Outlet />
                </div>
            </div>
    );
};
