import {DateTime, dateTime} from '@gravity-ui/date-utils';

export function getLocalDateTime(input: string): Date {
    return new Date(input);
}

/**
 * Форматирование дат без привязки к локальному часовому поясу
 * @param dateInput - Дата, которую нужно отформатировать
 * @param formats - Строка или массив строк форматов (для указания времени и даты)
 * @returns Отформатированная строка или массив строк
 */
export const formatDate = (
    dateInput: Date,
    formats: string | string[]
): string | string[] => {
    const dateTimeInput = dateTime({input: dateInput});
    return formatDateTime(dateTimeInput, formats);
};

export const formatDateTime = (
    date: DateTime,
    formats: string | string[]
): string | string[] => {
    if (typeof(formats) === 'string')
        return date.format(formats)
    return formats.map((format) => date.format(format));
};

export interface ParsedDateRange {
    dateFrom?: string;
    dateTo?: string;
}

/**
 * Функция обработки диапазона дат в фильтрах
 * Парсит диапазон дат и возвращает объект с полями `dateFrom` и `dateTo` в формате ISO.
 * @param start - начальная дата диапазона
 * @param end - конечная дата диапазона
 */
export const parseDateRange = (
    start?: DateTime,
    end?: DateTime
): ParsedDateRange => {
    const format = 'YYYY-MM-DDTHH:mm:ssZ';
    return {
        dateFrom: start ? formatDateTime(start, format) as string : undefined,
        dateTo: end ? formatDateTime(end, format) as string : undefined
    };
};
