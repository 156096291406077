import {useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Avatar, Icon, Alert} from '@gravity-ui/uikit';
import {Person, Envelope, Handset} from '@gravity-ui/icons';
import {AppContext} from '@app/context';

import './UserInfo.scss';

const b = block('user-info');

export const UserInfo: React.FC = () => {
    const {
        state: {user}
    } = useContext(AppContext);

    // При "npm run build" react-scripts выставит NODE_ENV=production и email скроется
    const isProd = process.env.NODE_ENV === 'production';

    return (
        <div className={b()}>
            <Text variant="header-1">Профиль</Text>
            <Avatar
                className={b('avatar')}
                size="xl"
                theme="brand"
                text={user?.displayName || ''}
            />
            <Text className={b('content')} variant="body-2">
                <Icon className={b('icon')} data={Person} />
                {user?.displayName || ''}
            </Text>
            {!isProd && (
                <>
                    <Text className={b('content')} variant="body-2">
                        <Icon className={b('icon')} data={Envelope} />
                        {user?.email}
                    </Text>
                </>
            )}
            <Text className={b('content')} variant="body-2">
                <Icon className={b('icon')} data={Handset} />
                {user?.phone || ''}
            </Text>
            <Alert
                theme="info"
                className={b('alert')}
                message="Для внесения изменений в профиль обратитесь к вашему менеджеру"
            />
        </div>
    );
};
