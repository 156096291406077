import {formatCurrencyRub} from '@utils';
import {BalanceData} from '../interfaces/BalanceData';
import {Balance} from '@services/user-gw';

export const transformBalanceData = (data: Balance): BalanceData => ({
    currentBalance: formatCurrencyRub(data.currentBalance),
    availableForOrder: formatCurrencyRub(data.availableForOrder),
    debt: data.debt
        ? {
              total: formatCurrencyRub(data.debt.total),
              expired: formatCurrencyRub(data.debt.expired),
              expiredDays: data.debt.expiredDays
          }
        : {
              total: '0 ₽',
              expired: '0 ₽',
              expiredDays: 0
          },
    upcomingPayments: data.upcomingPayments
        ? {
              tomorrow: formatCurrencyRub(data.upcomingPayments.tomorrow),
              closest: {
                  amount: formatCurrencyRub(data.upcomingPayments.closest.amount),
                  date: new Date(data.upcomingPayments.closest.date)
              }
          }
        : {
              tomorrow: '0 ₽',
              closest: {
                  amount: '0 ₽',
                  date: null
              }
          },
    orders: {
        unpaid: formatCurrencyRub(data.orders.unpaid),
        inProgress: formatCurrencyRub(data.orders.inProgress),
        ready: formatCurrencyRub(data.orders.ready)
    },
    deniedFlags: data.deniedFlags
});
