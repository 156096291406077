import {useToast} from '@hooks';
import {useState} from 'react';
import {useBasket} from './useBasket';

interface CheckBasketOptions {
    showSuccessToast?: boolean;
}

export const useCheckBasket = () => {
    const {refreshBasketItems} = useBasket();
    const {showToast} = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Проверяем наличие и цены');

    const checkBasket = async (options: CheckBasketOptions = {}): Promise<boolean> => {
        const {showSuccessToast = true} = options;
        setIsLoading(true);
        setLoadingText('Проверяем наличие и цены');
        try {
            const {
                priceChanged,
                itemsCntExceedsQuantity,
                itemsMovedToUnavailable,
                priceIncreased,
                priceDecreased
            } = await refreshBasketItems();

            const hasItemsMovedToUnavailable = itemsMovedToUnavailable.length > 0;

            if (!priceChanged && !hasItemsMovedToUnavailable && !itemsCntExceedsQuantity) {
                if (showSuccessToast) {
                    showToast('success', 'Цены и наличие не изменились');
                }
                return true;
            } else {
                let toastType: 'success' | 'danger' | 'warning' = 'warning';
                let message = '';
                let isPriceDecreasedOnly = false;

                if (hasItemsMovedToUnavailable && itemsCntExceedsQuantity) {
                    toastType = 'danger';
                    message = 'Количество доступных позиций по товарам в корзине изменилось';
                } else if (hasItemsMovedToUnavailable) {
                    toastType = 'danger';
                    message = 'Доступность части товаров в корзине изменилась';
                } else if (itemsCntExceedsQuantity && priceChanged) {
                    toastType = 'warning';
                    message = 'Цена и доступное количество товаров в корзине изменились';
                } else if (itemsCntExceedsQuantity) {
                    toastType = 'warning';
                    message = 'Количество доступных позиций по товарам в корзине изменилось';
                } else if (priceChanged) {
                    // Проверяем, что цены изменились только в меньшую сторону
                    if (priceDecreased && !priceIncreased) {
                        toastType = 'success';
                        message = 'Цены на часть товаров в корзине изменились';
                        isPriceDecreasedOnly = true;
                    } else {
                        toastType = 'warning';
                        message = 'Цены на часть товаров в корзине изменились';
                    }
                }

                if (isPriceDecreasedOnly) {
                    showToast(toastType, message, {isPriceDecreasedOnly: true});
                } else {
                    showToast(toastType, message);
                }

                return false;
            }
        } catch (error) {
            showToast('danger', 'Произошла ошибка при проверке товаров', {isError: true});
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    return {checkBasket, isLoading, loadingText, setLoadingText};
};
