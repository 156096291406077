import {IUser, userFromToken, userStorage} from './user';
import React from 'react';
import {tokenStorage} from './token';
import {EventName} from './events';
import {countersStorage} from './counters';
import {BasketItemModel} from '@features/basket/interfaces';
import {BasketSummaryModel} from '@features/basket';
import {basketStorage} from './basketStorage';
import {FiltersModel, OrderItemModel} from '@features/orders/interfaces';
import {PriceLevelSettings, priceLevelSettingsStorage} from './priceLevelSettings';
import {ordersFiltersStorage} from './ordersFiltersStorage';
import {ClientDetails} from './interfaces/ClientDetails';
import {ordersSettingsStorage} from './ordersSettingsStorage';

export interface Context {
    state: AppState;
    emit: (name: EventName, event?: object) => void;
}

export interface AppState {
    user?: IUser;
    token?: string;
    counters: Record<string, number>;
    isBrandSearchActive: boolean; // поле для добавления в хлебные крошки "Уточнение запроса" в хедере
    basket: {
        items: BasketItemModel[];
        itemsOut: BasketItemModel[];
        summary: BasketSummaryModel;
        allChecked: boolean;
        allCheckedItemsOut: boolean;
        selectedCount: number;
    };
    orders: {
        items: OrderItemModel[];
        config: {
            itemsOrder: number[];
            itemsChecked: Record<number, boolean>;
        };
        filters: FiltersModel;
    };
    priceLevelSettings: PriceLevelSettings;
    clientDetails?: ClientDetails;
}

export const AppContext = React.createContext<Context>({
    state: {
        counters: {},
        isBrandSearchActive: false,
        basket: {
            items: [],
            itemsOut: [],
            summary: {
                totalAmount: 0,
                totalQuantity: 0
            },
            allChecked: true,
            allCheckedItemsOut: true,
            selectedCount: 0
        },
        orders: {
            items: [],
            config: {
                itemsOrder: [],
                itemsChecked: {}
            },
            filters: {
                createdAtRange: null,
                deliveryDateRange: null,
                statuses: [],
                brands: [],
                refs: [],
                articles: [],
                price: {},
                sum: {},
                onlyWithComments: false
            }
        },
        priceLevelSettings: {
            enabled: false,
            shouldDisableFields:false,
            markupPercentage: 0,
            displayInSearchResults: false,
            displayInBasket: false
        }
    },
    emit: (_) => {}
});

export const loadStateFromLS = (): AppState => {
    const token = tokenStorage.get();
    const storedBasket = basketStorage.get();
    let initialCounters = countersStorage.get();
    const storedOrdersFilters = ordersFiltersStorage.get();
    const storedOrdersSettings = ordersSettingsStorage.get();

    const initialBasketState: AppState['basket'] = {
        items:
            storedBasket?.items?.map((item) => ({
                ...item,
                checked: item.checked ?? true
            })) || [],
        itemsOut: storedBasket?.itemsOut || [],
        summary: storedBasket?.summary || {
            totalAmount: 0,
            totalQuantity: 0
        },
        allChecked: storedBasket?.allChecked ?? true,
        allCheckedItemsOut: storedBasket?.allCheckedItemsOut ?? true,
        selectedCount: storedBasket?.selectedCount ?? 0
    };

    const initialOrdersState: AppState['orders'] = {
        items: [],
        config: storedOrdersSettings || {
            itemsOrder: Array.from({length: 10}, (_, i) => i + 1),
            itemsChecked: Array.from({length: 10}, (_, i) => i + 1).reduce(
                (acc, id) => {
                    acc[id] = true;
                    return acc;
                },
                {} as Record<number, boolean>
            )
        },
        filters: storedOrdersFilters || {
            createdAtRange: null,
            deliveryDateRange: null,
            statuses: [],
            brands: [],
            refs: [],
            articles: [],
            price: {},
            sum: {},
            onlyWithComments: false
        }
    };

    initialCounters['basketItems'] = initialBasketState.items.length;
    countersStorage.set(initialCounters);

    const initialPriceLevelSettings = priceLevelSettingsStorage.get() || {
        enabled: false,
        shouldDisableFields:false,
        markupPercentage: 0,
        displayInSearchResults: false,
        displayInBasket: false
    };

    if (!token) {
        userStorage.clear();

        return {
            counters: countersStorage.get() || initialCounters,
            isBrandSearchActive: false,
            basket: basketStorage.get() || initialBasketState,
            orders: initialOrdersState,
            priceLevelSettings: initialPriceLevelSettings
        };
    }

    return {
        token: token,
        user: userFromToken(token),
        counters: countersStorage.get() || initialCounters,
        basket: basketStorage.get() || initialBasketState,
        isBrandSearchActive: false,
        orders: initialOrdersState,
        priceLevelSettings: initialPriceLevelSettings
    };
};
