import './Items.scss';
import {useContext, useMemo} from 'react';
import block from 'bem-cn-lite';
import {ArticleInfo, DeliveryDate, Price, ProposalBadges, Quantity} from '@components';
import {Text} from '@gravity-ui/uikit';
import {AppContext} from 'app/context';
import {AuxControls} from './components/AuxControls';
import {Status} from './components/Status';
import {CommentWithTooltip} from './components/CommentWithTooltip';
import {useMediaQuery} from 'react-responsive';
import {OrderItemModel} from '@features/orders/interfaces';
import {formatDate} from '@utils';

const b = block('orders-items');

interface ColumnMapping {
    className: string;
    width: string;
    render: () => JSX.Element;
}

interface ItemsProps {
    items: OrderItemModel[];
}

export const Items: React.FC<ItemsProps> = ({items}) => {
    const {state} = useContext(AppContext);
    const {config} = state.orders;
    const {itemsChecked, itemsOrder} = config;

    const isSmallScreen = useMediaQuery({query: '(max-width: 1440px)'});
    const columnWidths: Record<number, number> = useMemo(() => {
        return isSmallScreen
            ? {
                1: 98, // &__date-order
                2: 119, // &__date-delivery
                3: 101, // &__status
                4: 124, // &__ref
                5: 80, // &__bages
                6: 176, // &__name
                7: 82, // &__quantity
                8: 100, // &__price
                9: 100, // &__sum
                10: 128 // &__comment
            }
            : {
                1: 98,
                2: 119,
                3: 136,
                4: 116,
                5: 68,
                6: 241,
                7: 82,
                8: 124,
                9: 154,
                10: 148
            };
    }, [isSmallScreen]);

    return (
        <div className={b()}>
            {items?.map((item) => {
                const isOfficialDealer = !!item.flags?.official;
                const isReturnPossible = item.return !== 'impossible';

                const columnMappings: Record<number, ColumnMapping> = {
                    1: {
                        className: b('item', b('date')),
                        width: `${columnWidths[1]}px`,
                        render: () => (
                            <Text variant={'body-2'}>{formatDate(
                                item.createdAt,
                                'DD.MM.YY'
                            )}</Text>
                        )
                    },
                    2: {
                        className: b('item', b('delivery-date')),
                        width: `${columnWidths[2]}px`,
                        render: () => (
                            <DeliveryDate
                                value={item.deliveryDate}
                                isCustomOrder={true}
                                className={b('item', b('delivery-date'))}
                            />
                        )
                    },
                    3: {
                        className: b('item', b('status')),
                        width: `${columnWidths[3]}px`,
                        render: () => <Status item={item} />
                    },
                    4: {
                        className: b('item', b('ref')),
                        width: `${columnWidths[4]}px`,
                        render: () => <Text variant={'body-2'}>{item.refId}</Text>
                    },
                    5: {
                        className: b('item', b('icons')),
                        width: `${columnWidths[5]}px`,
                        render: () => (
                            <ProposalBadges
                                isOfficialDealer={isOfficialDealer}
                                isReturnPossible={isReturnPossible}
                                className={b('item', b('icons'))}
                            />
                        )
                    },
                    6: {
                        className: b('item', b('article')),
                        width: `${columnWidths[6]}px`,
                        render: () => (
                            <ArticleInfo
                                header={{
                                    article: item.article,
                                    articleName: item.articleName,
                                    brandName: item.brandName
                                }}
                                isCustomOrder={true}
                                className={b('item', b('article'))}
                            />
                        )
                    },
                    7: {
                        className: b('item', b('quantity')),
                        width: `${columnWidths[7]}px`,
                        render: () => (
                            <Quantity value={item.quantity} className={b('item', b('quantity'))} />
                        )
                    },
                    8: {
                        className: b('item', b('price')),
                        width: `${columnWidths[8]}px`,
                        render: () => <Price value={item.price} className={b('item', b('price'))} />
                    },
                    9: {
                        className: b('item', b('amount')),
                        width: `${columnWidths[9]}px`,
                        render: () => <Price value={item.sum} className={b('item', b('amount'))} />
                    },
                    10: {
                        className: b('item', b('comment')),
                        width: `${columnWidths[10]}px`,
                        render: () =>
                            item.comment ? (
                                <CommentWithTooltip comment={item.comment} />
                            ) : (
                                <Text variant={'body-1'} color="hint">
                                    Пусто
                                </Text>
                            )
                    }
                };

                // Определяем видимые и скрытые столбцы
                const visibleColumns = itemsOrder.filter((id) => itemsChecked[id]);
                const hiddenColumns = itemsOrder.filter((id) => !itemsChecked[id]);

                // Добавляем к ширине 'article' ширины скрытых столбцов
                let adjustedColumnMappings = {...columnMappings};
                if (visibleColumns.includes(6)) {
                    const extraWidth = hiddenColumns.reduce((sum, id) => sum + columnWidths[id], 0);
                    adjustedColumnMappings[6].width = `${columnWidths[6] + extraWidth}px`;
                }

                // Формируем gridTemplateColumns
                const gridTemplateColumns = visibleColumns.map((id: number) => {
                    return adjustedColumnMappings[id].width;
                });

                // Добавляем колонку для AuxControls
                gridTemplateColumns.push('88px');

                return (
                    <div
                        key={item.key}
                        className={b('row')}
                        style={{gridTemplateColumns: gridTemplateColumns.join(' ')}}
                    >
                        {visibleColumns.map((id) => (
                            <div key={id} className={adjustedColumnMappings[id].className}>
                                {adjustedColumnMappings[id].render()}
                            </div>
                        ))}

                        <AuxControls isReturnPossible={isReturnPossible} item={item} />
                    </div>
                );
            })}
        </div>
    );
};
