import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from 'app/context';
import {OrderSearch} from '@services/user-gw';
import {CApi as userGwApi} from '@services/api';
import {FiltersModel} from '@features/orders/interfaces/FiltersModel';
import {OrderItemModel} from '@features/orders/interfaces';
import {mapFieldToApiField} from '../utils/sortUtils';
import {getLocalDateTime, parseDateRange} from '@utils';
import {getArticles, getBrands, getRefIds, getStatuses} from '@features/orders/services';

export const useOrders = ({
    sortOption
}: {
    sortOption: {property: string; direction: 'asc' | 'desc'} | null;
}) => {
    const {state, emit} = useContext(AppContext);
    const {filters: contextFilters} = state.orders;
    const [items, setItems] = useState<OrderItemModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [filters, setFilters] = useState<FiltersModel>(contextFilters);

    const offsetRef = useRef<number>(0);
    const totalItemsRef = useRef<number>(0);

    const loadItems = useCallback(
        async (reset: boolean, loadLimit: number) => {
            if (isLoading) return;

            setIsLoading(true);

            const currentOffset = reset ? 0 : offsetRef.current;

            const filterData: OrderSearch['filter'] = {};

            if (filters.createdAtRange) {
                const {dateFrom, dateTo} = parseDateRange(
                    filters.createdAtRange.start,
                    filters.createdAtRange.end
                );

                if (dateFrom) filterData.dateFrom = dateFrom;
                if (dateTo) filterData.dateTo = dateTo;
            }

            if (filters.deliveryDateRange) {
                const {dateFrom, dateTo} = parseDateRange(
                    filters.deliveryDateRange.start,
                    filters.deliveryDateRange.end
                );

                if (dateFrom) filterData.deliveryDateFrom = dateFrom;
                if (dateTo) filterData.deliveryDateTo = dateTo;
            }

            const selectedStatuses = filters.statuses
                .filter((x) => x.isSelected)
                .map((x) => x.value);
            if (selectedStatuses.length > 0) {
                filterData.statusIn = selectedStatuses;
            }

            const selectedRefs = filters.refs.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedRefs.length > 0) {
                filterData.refIdIn = selectedRefs;
            }

            const selectedBrands = filters.brands.filter((x) => x.isSelected).map((x) => x.value);
            if (selectedBrands.length > 0) {
                filterData.brandNameIn = selectedBrands;
            }

            const selectedArticles = filters.articles
                .filter((x) => x.isSelected)
                .map((x) => x.value);
            if (selectedArticles.length > 0) {
                filterData.articleIn = selectedArticles;
            }

            if (filters.price.from) {
                filterData.priceFrom = filters.price.from;
            }
            if (filters.price.to) {
                filterData.priceTo = filters.price.to;
            }

            if (filters.sum.from) {
                filterData.sumFrom = filters.sum.from;
            }
            if (filters.sum.to) {
                filterData.sumTo = filters.sum.to;
            }

            if (filters.onlyWithComments) {
                filterData.hasComment = true;
            }

            const sortData: OrderSearch['sort'] = sortOption
                ? [
                      {
                          field: mapFieldToApiField(sortOption.property),
                          direction: sortOption.direction
                      }
                  ]
                : [
                      {
                          field: 'status_date',
                          direction: 'desc'
                      }
                  ];

            const searchData: OrderSearch = {
                limit: loadLimit,
                offset: currentOffset,
                filter: filterData,
                sort: sortData
            };

            try {
                const response = await userGwApi().orderSearch.orderSearchCreate(searchData);

                const newItems = response.data.items.map((x) => ({
                    key: `${x.refId}:${x.status.id}`,
                    article: x.article,
                    comment: x.comment,
                    articleName: x.articleName,
                    brandName: x.brandName,
                    createdAt: getLocalDateTime(x.createdAt),
                    flags: x.flags,
                    return: x.return,
                    deliveryDate: getLocalDateTime(x.deliveryInfo?.expected.client.date),
                    price: x.price,
                    quantity: x.quantity,
                    refId: x.refId,
                    status: {
                        ...x.status,
                        createdAt: getLocalDateTime(x.status.createdAt)
                    },
                    sum: x.sum
                })) as OrderItemModel[];

                totalItemsRef.current = response.data.total;

                setItems((prevItems) => {
                    const updatedItems = reset ? newItems : [...prevItems, ...newItems];

                    const uniqueItems = Array.from(
                        new Map(updatedItems.map((item) => [item.key, item])).values()
                    );

                    return uniqueItems;
                });

                // Вычисляем количество уникальных refId из ответа бэка
                const uniqueRefIdsCount = new Set(response.data.items.map((item) => item.refId))
                    .size;

                // Обновляем offset в рефе, используя уникальные refId
                offsetRef.current = currentOffset + uniqueRefIdsCount;

                // Проверяем, есть ли еще данные для загрузки
                setHasMore(offsetRef.current < response.data.total && uniqueRefIdsCount > 0);
            } catch (error) {
                console.error('Ошибка при загрузке заказов:', error);
            } finally {
                setIsLoading(false);
            }
        },
        [filters, isLoading, sortOption]
    );

    useEffect(() => {
        if (items.length > 0) {
            emit('updateOrders', {
                orders: {
                    items: items
                }
            });
        }
    }, [items]);

    useEffect(() => {
        // Загружаем значения для фильтров при первом рендере
        const loadFilters = async () => {
            try {
                const [refIdResponse, brandNameResponse, statusResponse, articleResponse] =
                    await Promise.all([getRefIds(), getBrands(), getStatuses(), getArticles()]);

                setFilters((prev) => ({
                    ...prev,
                    refs: prev.refs.length > 0 ? prev.refs : refIdResponse,
                    brands: prev.brands.length > 0 ? prev.brands : brandNameResponse,
                    statuses: prev.statuses.length > 0 ? prev.statuses : statusResponse,
                    articles: prev.articles.length > 0 ? prev.articles : articleResponse
                }));
            } catch (error) {
                console.error('Ошибка при загрузке фильтров:', error);
            }
        };

        loadFilters();
    }, []);

    useEffect(() => {
        // При изменении фильтров и сортировки сбрасываем offset и перезагружаем данные
        offsetRef.current = 0;
        setHasMore(true);
        loadItems(true, 50); // Загружаем первые 50 элементов
    }, [filters, sortOption]);

    const loadMoreItems = useCallback(() => {
        if (hasMore && !isLoading) {
            setIsLoading(true);
            setTimeout(() => {
                loadItems(false, 20); // дозагружаемые элементы
            }, 1000);
        }
    }, [hasMore, isLoading, loadItems]);

    return {
        items,
        filters,
        setFilters,
        isLoading,
        hasMore,
        loadMoreItems
    };
};
