import {SortFieldModel} from '../interfaces/SortFieldModel';

export const defaultDirection = new Map<string, number>([
    ['price', 1],
    ['delivery', -1],
    ['ref_id', 1],
    ['sum', 1],
    ['date', -1],
    ['status_date', -1]
]);

export const mapFieldToApiField: SortFieldModel = (field: string) => {
    switch (field) {
        case 'date':
            return 'date';
        case 'delivery':
            return 'delivery';
        case 'status_date':
            return 'status_date';
        case 'status':
            return 'status';
        case 'ref_id':
            return 'ref_id';
        case 'price':
            return 'price';
        case 'sum':
            return 'sum';
        default:
            return 'status_date';
    }
};
