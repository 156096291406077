import './DeliveryDate.scss';
import React from 'react';
import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';
import {CircleInfo} from '@gravity-ui/icons';
import {formatDate} from '@utils';

const b = block('delivery-date');

export interface DeliveryDateProps {
    value: Date;
    className: string;
    isCustomOrder?: boolean;
}

export const DeliveryDate: React.FC<DeliveryDateProps> = (props) => {
    const formattedDate = formatDate(props.value, 'DD.MM.YY');

    return (
        <div
            className={`${b()} ${props.className} ${props.isCustomOrder ? b('custom-order') : ''}`}
        >
            {props.isCustomOrder ? (
                <>
                    <Text variant={'body-2'}>{formattedDate}</Text>
                    {/* style={{display: 'none'}} так как для MVP следующую дату не показываем: https://docs.google.com/document/d/1e73xQFoXYX0sK2aRPhkb8TagVPMtgVTmc4iaROicp6Q/edit?disco=AAABVNU9fig */}
                    <div className={b('expected-date')} style={{display: 'none'}}>
                        <Text variant={'body-2'} color={'secondary'}>
                            {formattedDate}
                        </Text>
                        <CircleInfo className={b('expected-icon')} />
                    </div>
                </>
            ) : (
                <Text variant={'body-2'} color={'secondary'}>
                    {formattedDate}
                </Text>
            )}
        </div>
    );
};
