import {useCallback, useEffect, useState} from 'react';

const useIsTabActive = () => {
    const [isTabVisible, setIsTabVisible] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        setIsTabVisible(document.visibilityState === 'visible');
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return isTabVisible;
};

export default useIsTabActive;