import React, {useContext} from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {CircleRuble} from '@gravity-ui/icons';
import {AppContext} from 'app/context';
import {BalanceData} from '@features/balance/interfaces/BalanceData';
import {parseAmount} from '@features/balance/utils/parseAmount';

import './CurrentBalanceCard.scss';

const b = block('current-balance-card');

interface CurrentBalanceCardProps {
    balanceData: BalanceData;
}

export const CurrentBalanceCard: React.FC<CurrentBalanceCardProps> = ({balanceData}) => {
    const {state} = useContext(AppContext);
    const balanceRestrictionType = state.clientDetails?.balance.balanceRestrictionType;

    const isDebtExpired = parseAmount(balanceData.debt?.expired) > 0;
    const isOrdersProgress = parseAmount(balanceData.orders.inProgress) > 0;
    const isOrdersReady = parseAmount(balanceData.orders.ready) > 0;

    return (
        <div className={b()}>
            <div className={b('current-balance')}>
                <Icon
                    data={CircleRuble}
                    width={32}
                    height={32}
                    className={b('current-balance-icon')}
                />
                <div className={b('current-balance-amount')}>
                    <Text variant="body-2" color="secondary">
                        Текущий баланс
                    </Text>

                    <Text variant="display-1">{balanceData.currentBalance}</Text>
                </div>
            </div>

            {balanceRestrictionType !== 'retail' && (
                <>
                    <div className={b('debt')}>
                        <div className={b('debt-amount')}>
                            <Text variant="body-2" color="secondary">
                                Задолженность
                            </Text>

                            <Text variant="header-1">{balanceData.debt?.total}</Text>
                        </div>
                        <div className={b('debt-overdue')}>
                            <Text variant="body-2" color={isDebtExpired ? 'secondary' : 'hint'}>
                                в т.ч. просроченная
                            </Text>

                            <Text variant="header-1" color={isDebtExpired ? 'danger' : 'hint'}>
                                {balanceData.debt?.expired}{' '}
                                {isDebtExpired && (
                                    <Text variant="body-2" color="danger">
                                        ({balanceData.debt?.expiredDays} дн.)
                                    </Text>
                                )}
                            </Text>
                        </div>
                    </div>

                    <div className={b('orders')}>
                        <div className={b('orders-amount')}>
                            <Text variant="body-2" color="secondary">
                                Неоплаченные заказы
                            </Text>
                            <Text variant="header-1">{balanceData.orders.unpaid}</Text>
                        </div>
                        <div className={b('orders-statuses')}>
                            <div className={b('status')}>
                                <Text
                                    variant="body-2"
                                    color={isOrdersProgress ? 'secondary' : 'hint'}
                                >
                                    в т.ч. в работе
                                </Text>
                                <Text
                                    variant="header-1"
                                    color={isOrdersProgress ? 'secondary' : 'hint'}
                                >
                                    {balanceData.orders.inProgress}
                                </Text>
                            </div>
                            <div className={b('status')}>
                                <Text variant="body-2" color={isOrdersReady ? 'secondary' : 'hint'}>
                                    готовы к выдаче
                                </Text>
                                <Text
                                    variant="header-1"
                                    color={isOrdersReady ? 'secondary' : 'hint'}
                                >
                                    {balanceData.orders.ready}
                                </Text>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
