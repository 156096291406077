import React, {useContext, useState} from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {Check} from '@gravity-ui/icons';
import {AppContext} from 'app/context';

import './PopupClientsAddress.scss';

const b = block('popup-clients-address');

export const PopupClientsAddress = () => {
    const {state} = useContext(AppContext);
    const defaultAddress = state.clientDetails?.warehouse?.address;
    const companyName = state.clientDetails?.name;

    const [selectedAddress, setSelectedAddress] = useState(defaultAddress);

    const fakeAddresses = [
        {address: 'Новосибирск г, Ленина ул, дом 12', name: 'Название компании'},
        {address: 'Адрес', name: 'Название компании'}
    ];

    const allAddresses = [
        ...(defaultAddress ? [{ address: defaultAddress, name: companyName }] : []),
        ...fakeAddresses,
    ];

    return (
        <div className={b()}>
            <div className={b('header')}>
                <Text variant="body-1" color="secondary">
                    Адреса доставки
                </Text>
            </div>

            <div className={b('content')}>
                {allAddresses.map((item, index) => (
                    <div
                        key={index}
                        className={b('item')}
                        onClick={() => setSelectedAddress(item.address)}
                    >
                        <div className={b('item-icon')}>
                            {selectedAddress === item.address && (
                                <Icon data={Check} width="16" height="16" />
                            )}
                        </div>

                        <div className={b('item-info')}>
                            <Text variant="body-1">{item.address}</Text>
                            <Text variant="body-1" color="secondary">
                                {item.name}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
