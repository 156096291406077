import React, {useContext} from 'react';
import block from 'bem-cn-lite';
import {AppContext} from 'app/context';
import {Text} from '@gravity-ui/uikit';
import {useMediaQuery} from 'react-responsive';

import './BalanceElement.scss';

const b = block('header-balance-element');

export const BalanceElement = () => {
    const isWideScreen = useMediaQuery({query: '(min-width: 1920px)'});
    const {state} = useContext(AppContext);
    const balanceRestrictionType = state.clientDetails?.balance.balanceRestrictionType;

    const expiredDebt = state.clientDetails?.balance.expiredDebt ?? 0;
    const availableAmount = state.clientDetails?.balance.availableAmount ?? 0;
    const minimumPaymentTomorrow = state.clientDetails?.balance.minimumPaymentTomorrow ?? 0;

    let content;

    const createTextElement = (label: string, value: number, isDanger = false) => (
        <div className={b('text-element')}>
            <Text variant={isWideScreen ? 'body-2' : 'body-1'}>{label}</Text>
            <Text
                className={b(isDanger ? 'red-text-total' : 'text-total')}
                variant={isWideScreen ? 'body-2' : 'body-1'}
                color={isDanger ? 'danger' : 'primary'}
            >
                {value.toLocaleString('ru-RU')} ₽
            </Text>
        </div>
    );

    if (
        balanceRestrictionType === 'post_payment' ||
        balanceRestrictionType === 'prepaid_shipment'
    ) {
        content = (
            <>
                {createTextElement('Просроченная задолженность:', expiredDebt, expiredDebt > 0)}
                {createTextElement('Min оплата на завтра:', minimumPaymentTomorrow)}
                {createTextElement('Доступно для заказа:', availableAmount)}
            </>
        );
    } else if (balanceRestrictionType === 'prepaid_receiving') {
        content = (
            <>
                {expiredDebt > 0 &&
                    createTextElement('Просроченная задолженность:', expiredDebt, expiredDebt > 0)}
                {createTextElement('Доступно для заказа:', availableAmount)}
            </>
        );
    } else if (balanceRestrictionType === 'retail') {
        content = createTextElement('Баланс:', availableAmount);
    }

    return <div className={b()}>{content}</div>;
};
