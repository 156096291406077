import React, {useContext, useState} from 'react';
import block from 'bem-cn-lite';
import {Item, SectionHeader, SectionItems} from './components';
import {AsyncSearchState, SectionItemModel} from '@features/search/interfaces';
import './Section.scss';
import {Loader} from '@gravity-ui/uikit';
import {SearchContext} from '@features/search/context';

const b = block('section');

interface SectionProps {
    title: string;
    items: SectionItemModel[];
    isTopLevel?: boolean;
}

export const Section: React.FC<SectionProps> = ({title, items, isTopLevel = false}) => {
    const [isHidden, setIsHidden] = useState(false);
    const searchContext = useContext(SearchContext);

    function toggleVisibility() {
        setIsHidden((prev) => !prev);
    }

    const isLoading =
        isTopLevel && searchContext.state === AsyncSearchState.Searching && items.length === 0;

    return isTopLevel || items.length > 0 ? (
        <div className={b({hidden: isHidden})}>
            <SectionHeader
                title={title}
                isHidden={isHidden}
                onToggleVisibility={toggleVisibility}
                isTopLevel={isTopLevel}
            />
            {isLoading ? (
                <div className={b('loader-container')}>
                    <Loader size="m" />
                </div>
            ) : items.length > 0 ? (
                <SectionItems hiddenSection={isHidden}>
                    {items.map((item) => (
                        <Item key={item.key} header={item.header} proposals={item.proposals} />
                    ))}
                </SectionItems>
            ) : (
                <div className={b('not-found', {visibility: isHidden})}>
                    На данный момент деталь отсутствует.
                </div>
            )}
        </div>
    ) : null;
};
