import React from 'react';
import block from 'bem-cn-lite';
import {UserInfo, UserNotification} from '@features/profile';

import './PersonInfoPage.scss';

const b = block('person-info');

export const PersonInfoPage: React.FC = () => {
    return (
        <div className={b()}>
            <UserInfo />
            {/* <UserNotification/> */}
        </div>
    );
};
