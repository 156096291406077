import './SearchBar.scss';
import React, {useContext, useEffect, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {Button, Checkbox, Icon, List, Text, TextInput} from '@gravity-ui/uikit';
import {BasketButton} from './components';
import {useDebounce} from 'use-debounce';
import {SearchInputModel, SearchRequest, SuggestItem} from '@features/search/interfaces';
import {CApi as userGwApi} from '@services/api';
import {AppContext} from 'app/context';
import {getAdjustedPrice} from '@utils/getAdjustedPrice';
import {Magnifier} from '@gravity-ui/icons';
import {cleanSearchValue} from '@utils';

const b = block('search-bar');

export interface SearchBarProps {
    className?: string;
    searchText: SearchInputModel;
    searchBrand: string;
    useAnalogs: boolean;
    searchHistory: SearchRequest[];
    onSearchClick?: () => void;
    onInputChange?: (value: SearchInputModel) => void;
    onBrandSelected?: (brand: SuggestItem) => void;
    onUseAnalogsChanged?: (value: boolean) => void;
    onBrandSuggestionsChanged?: (suggestions: SuggestItem[]) => void;
    onSearchHistorySelected?: (searchHistory: SearchRequest) => void;
    size?: 'm' | 'l' | 's';
    onInputFocus?: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
    onSearchClick,
    onInputChange,
    onBrandSelected,
    onUseAnalogsChanged,
    onBrandSuggestionsChanged,
    onSearchHistorySelected,
    searchText,
    useAnalogs,
    size,
    searchHistory
}) => {
    const isInit = useRef(true);
    const searchTextRef = useRef(searchText);
    const listRef = useRef(null);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const [searchInputFocus, setSearchInputFocus] = useState(false);
    const [suggestItems, setSuggestItems] = useState<SuggestItem[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(true);
    const [showSearchHistory, setShowSearchHistory] = useState(false);

    const [debouncedSearchInputValue] = useDebounce(searchText, 300);
    const [debouncedSearchInputFocus] = useDebounce(searchInputFocus, 100);

    const [isInItem, setIsInItem] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const {state} = useContext(AppContext);

    function onSearchInputChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        isInit.current = false;
        if (onInputChange) {
            const cleanedValue = cleanSearchValue(event.target.value);
            searchTextRef.current = {
                originalValue: event.target.value,
                cleanedValue: cleanedValue,
                needToSuggestBrands: true
            };
            onInputChange(searchTextRef.current);
        }
    }

    function onUseAnalogsChangedHandler(value: boolean) {
        if (onUseAnalogsChanged) onUseAnalogsChanged(value);
    }

    const handleInputFocus = () => {
        setSearchInputFocus(true);

        const shouldShowSuggestions =
            searchText.originalValue.length > 0 && suggestItems.length > 0;
        const shouldShowSearchHistory =
            searchText.originalValue.length === 0 && searchHistory.length > 0;

        setShowSuggestions(shouldShowSuggestions);
        setShowSearchHistory(!shouldShowSuggestions && shouldShowSearchHistory);
    };

    const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const target = event.relatedTarget as HTMLElement | null;
        if (target && listContainerRef.current?.contains(target)) {
            return;
        }
        setSearchInputFocus(false);
        setShowSuggestions(false);
        setShowSearchHistory(false);
    };

    function handleBrandSelected(brand: SuggestItem) {
        setShowSuggestions(false);
        setShowSearchHistory(false);
        if (onBrandSelected) onBrandSelected(brand);
    }

    function handleSearchHistorySelected(searchHistory: SearchRequest) {
        setShowSuggestions(false);
        setShowSearchHistory(false);
        isInit.current = true;
        if (onSearchHistorySelected) onSearchHistorySelected(searchHistory);
    }

    function handleSearchClick() {
        const list = listRef.current as unknown as List;
        if (list !== null) {
            const currentIndex = list.getActiveItem();
            if (currentIndex !== null && currentIndex >= 0) {
                const brand = list.getItems()[currentIndex] as SuggestItem;
                if (brand !== null) {
                    handleBrandSelected(brand);
                    return;
                }
            }
        }

        setShowSuggestions(false);
        setShowSearchHistory(false);
        setSuggestItems([]);
        if (onSearchClick) onSearchClick();
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (showSuggestions && suggestItems.length > 0) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex + 1) % suggestItems.length);
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                setSelectedIndex(
                    (prevIndex) => (prevIndex - 1 + suggestItems.length) % suggestItems.length
                );
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (selectedIndex >= 0 && selectedIndex < suggestItems.length) {
                    const selectedItem = suggestItems[selectedIndex];
                    handleBrandSelected(selectedItem);
                } else {
                    handleSearchClick();
                }
            }
        } else if (showSearchHistory && searchHistory.length > 0) {
            if (event.key === 'ArrowDown') {
                event.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex + 1) % searchHistory.length);
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                setSelectedIndex(
                    (prevIndex) => (prevIndex - 1 + searchHistory.length) % searchHistory.length
                );
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (selectedIndex >= 0 && selectedIndex < searchHistory.length) {
                    const selectedItem = searchHistory[selectedIndex];
                    handleSearchHistorySelected(selectedItem);
                } else {
                    handleSearchClick();
                }
            }
        } else if (event.key === 'Enter') {
            event.preventDefault();
            handleSearchClick();
        }
    }

    function handleSuggestionItemMouseEnter() {
        setIsInItem(true);
    }

    function handleSuggestionItemMouseLeave() {
        setIsInItem(false);
    }

    useEffect(() => {
        let ignore = false;

        if (isInit.current)
            return () => {
                ignore = true;
            };

        if (debouncedSearchInputValue?.cleanedValue) {
            if (!debouncedSearchInputValue.needToSuggestBrands)
                return () => {
                    ignore = true;
                };

            userGwApi()
                .suggestBrand.suggestBrandList({
                    article: debouncedSearchInputValue.cleanedValue
                })
                .then((r) => {
                    if (
                        !ignore &&
                        searchTextRef.current.cleanedValue ===
                        debouncedSearchInputValue.cleanedValue
                    ) {
                        setSuggestItems(r.data.items as SuggestItem[]);
                        setShowSuggestions(r.data.items.length > 0);
                        setShowSearchHistory(false);
                        if (onBrandSuggestionsChanged)
                            onBrandSuggestionsChanged(r.data.items as SuggestItem[]);
                    }
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
        } else {
            setSuggestItems([]);
            setShowSuggestions(false);
            setShowSearchHistory(searchHistory.length > 0);
            if (onBrandSuggestionsChanged) onBrandSuggestionsChanged([]);
        }

        return () => {
            ignore = true;
        };
    }, [debouncedSearchInputValue]);

    // show suggestions
    useEffect(() => {
        if (!searchInputFocus) return;
        const showSuggestions =
            searchInputFocus && searchText.originalValue.length > 0 && suggestItems.length > 0;
        setShowSuggestions(showSuggestions);
        const showSearchHistory =
            searchInputFocus && searchText.originalValue.length === 0 && searchHistory.length > 0;
        setShowSearchHistory(!showSuggestions && showSearchHistory);
    }, [searchInputFocus]);

    // hide suggestions
    useEffect(() => {
        if (debouncedSearchInputFocus || isInItem) return;
        setShowSuggestions(false);
        setShowSearchHistory(false);
    }, [debouncedSearchInputFocus, isInItem]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement | null;
            if (
                target &&
                listContainerRef.current &&
                !listContainerRef.current.contains(target) &&
                !target.closest('input')
            ) {
                setShowSuggestions(false);
                setShowSearchHistory(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Получаем количество элементов в корзине (кроме недоступных)
    const basketItemCount =
        state.basket.items.filter((item) => item.status === 'actual' || item.status === 'stale')
            .length || 0;

    const totalAmount =
        getAdjustedPrice(state.basket.summary.totalAmount, state.priceLevelSettings, 'search') || 0;

    const placeholder = size === 's' ? 'Поиск по артикулу' : 'Поиск по артикулу, VIN';

    return (
        <div className={b() + ' ' + size}>
            <div className={b('search-fields')}>
                <div className={b('search-input-group')}>
                    <div className={b('search-input-group-container')}>
                        <TextInput
                            size="xl"
                            view="normal"
                            placeholder={placeholder}
                            className={b('search-input')}
                            value={searchText.originalValue}
                            onChange={onSearchInputChangeHandler}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onKeyDown={(e) => handleKeyDown(e)}
                            rightContent={
                                <>
                                    <Checkbox
                                        size="l"
                                        checked={useAnalogs}
                                        onChange={(x) =>
                                            onUseAnalogsChangedHandler(x.target.checked)
                                        }
                                    >
                                        Аналоги
                                    </Checkbox>

                                    {size === 's' && (
                                        <Button
                                            size="l"
                                            className={b('search-button')}
                                            onClick={handleSearchClick}
                                        >
                                            <Icon
                                                data={Magnifier}
                                                width="16"
                                                height="16"
                                                className={b('search-icon')}
                                            />
                                        </Button>
                                    )}
                                </>
                            }
                        ></TextInput>
                    </div>
                    {showSuggestions ? (
                        <div ref={listContainerRef}>
                            <List
                                items={suggestItems}
                                className={b('search-suggestions')}
                                itemHeight={50}
                                filterable={true}
                                itemsHeight={() => {
                                    const heigthItems = 50 * suggestItems.length + 4;
                                    const heightScreen = window.innerHeight - 388; // TODO Вынести в константы
                                    return heigthItems < heightScreen ? heigthItems : heightScreen;
                                }}
                                onItemClick={handleBrandSelected}
                                renderItem={(item) => {
                                    return (
                                        <div
                                            onMouseEnter={() => handleSuggestionItemMouseEnter()}
                                            onMouseLeave={() => handleSuggestionItemMouseLeave()}
                                            className={b('search-suggestions-inner-item')}
                                        >
                                            <div className={b('search-suggestions-brand')}>
                                                <Text variant="body-1">
                                                    {searchText.originalValue +
                                                        ' (' +
                                                        item.name +
                                                        ')'}
                                                </Text>
                                            </div>
                                            <Text variant="body-1" color="secondary">
                                                {item.articleName}
                                            </Text>
                                        </div>
                                    );
                                }}
                                itemsClassName={b('search-suggestions-items')}
                                itemClassName={b('search-suggestions-item')}
                                filterClassName={b('search-suggestions-filter')}
                                filterPlaceholder="Производители:"
                                activeItemIndex={selectedIndex}
                            ></List>
                        </div>
                    ) : (
                        <></>
                    )}
                    {showSearchHistory ? (
                        <div ref={listContainerRef}>
                            <List
                                items={searchHistory}
                                className={b('search-suggestions')}
                                itemHeight={50}
                                filterable={true}
                                itemsHeight={() => {
                                    const heigthItems = 50 * searchHistory.length + 4;
                                    const heightScreen = window.innerHeight - 388;
                                    return heigthItems < heightScreen ? heigthItems : heightScreen;
                                }}
                                onItemClick={handleSearchHistorySelected}
                                renderItem={(item) => {
                                    return (
                                        <div
                                            onMouseEnter={() => handleSuggestionItemMouseEnter()}
                                            onMouseLeave={() => handleSuggestionItemMouseLeave()}
                                            className={b('search-suggestions-inner-item')}
                                        >
                                            <div className={b('search-suggestions-brand')}>
                                                <Text variant="body-1">
                                                    {item.article + ' (' + item.brand + ')'}
                                                </Text>
                                            </div>
                                            <Text variant="body-1" color="secondary">
                                                {item.articleName}
                                            </Text>
                                        </div>
                                    );
                                }}
                                itemsClassName={b('search-suggestions-items')}
                                itemClassName={b('search-suggestions-item')}
                                filterClassName={b('search-suggestions-filter')}
                                filterPlaceholder="Последние запросы:"
                                activeItemIndex={selectedIndex}
                            ></List>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>

                {size !== 's' && (
                    <>
                        <Button size="xl" view="action" onClick={handleSearchClick}>
                            Искать
                        </Button>
                        <BasketButton totalItems={basketItemCount} totalAmount={totalAmount} />
                    </>
                )}
            </div>
        </div>
    );
};
