import {Trolley, Check, Box, Snail, Xmark, ShoppingCart, Wrench, Car} from '@gravity-ui/icons';

export const getStatusIcon = (status: string) => {
    switch (status) {
        case 'Котгрузке':
            return (
                <Trolley
                    style={{
                        color: 'rgba(100, 122, 141, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'Выдан':
            return (
                <Check
                    style={{
                        color: 'rgba(48, 170, 110, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'service.created':
            return (
                <Box
                    style={{
                        color: 'rgba(100, 122, 141, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'Втранзите':
            return (
                <Snail
                    style={{
                        color: 'rgba(100, 122, 141, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'Отказ':
            return (
                <Xmark
                    style={{
                        color: 'rgba(233, 3, 58, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'Взаказе':
            return (
                <ShoppingCart
                    style={{
                        color: 'rgba(100, 122, 141, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'Вработе':
            return (
                <Wrench
                    style={{
                        color: 'rgba(100, 122, 141, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        case 'Впути':
            return (
                <Car
                    style={{
                        color: 'rgba(100, 122, 141, 1)',
                        verticalAlign: 'sub',
                        marginRight: '4px'
                    }}
                />
            );
        default:
            return null;
    }
};
