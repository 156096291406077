import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {CircleInfo, ChevronUp} from '@gravity-ui/icons';
import './AlertHowUse.scss';

const b = block('alert-how-use');

export const AlertHowUse: React.FC = () => {
    const [showAlert, setShowAlert] = useState(true);

    const handleContextToggle = () => {
        setShowAlert(prevState => !prevState);
    };

    return (
        <div className={b()}>
            <Icon data={CircleInfo} size={18} className={b('icon')}></Icon>
            <div className={b('header-container')} onClick={handleContextToggle}>
                <Text variant='subheader-2'>Как пользоваться</Text>
                <div className={b('chevron', {'turn': showAlert})}>
                    <Icon data={ChevronUp} size={20}></Icon>
                </div>
            </div>
            <div className={b('context', {hidden: showAlert})}>
                <p>
                    Уровень цен позволяет добавлять наценку
                    к базовым ценам товаров, которую можно увидеть в результатах поиска и/или в корзине.
                </p>
                <ol>
                    <li>
                        <p>1. Настройка наценки в профиле:</p>
                        <ul>
                            <li>введите процент наценки (например, 5% или 10,5%).</li>
                        </ul>
                    </li>
                    <li>
                        <p>2. Выберите, где отображать наценку:</p>
                        <ul>
                            <li>в поиске, корзине или в обоих разделах.</li>
                        </ul>
                    </li>
                </ol>
                <p>Настройки применятся сразу после ввода данных.</p>
            </div>
        </div>
    );
};
