const REFRESH_BASKET_KEY = 'at.ug.refreshBasket';

class RefreshBasketStorage {
    private readonly TIMESTAMP_KEY = `${REFRESH_BASKET_KEY}_timestamp`;

    set = (value: boolean) => {
        const now = Date.now();

        if (!value) {
            // Если значение false, обновляем без учёта времени
            localStorage.setItem(REFRESH_BASKET_KEY, JSON.stringify(value));
            return;
        }

        const lastUpdated = this.getLastUpdated();

        // Если прошло меньше 30 минут, не обновляем значение true
        if (lastUpdated && now - lastUpdated < 1800000) {
            return;
        }

        // Записываем значение true и обновляем метку времени
        localStorage.setItem(REFRESH_BASKET_KEY, JSON.stringify(value));
        localStorage.setItem(this.TIMESTAMP_KEY, now.toString());
    };

    get = (): boolean => {
        const value = localStorage.getItem(REFRESH_BASKET_KEY);
        return value ? JSON.parse(value) : false;
    };

    clear = () => {
        localStorage.removeItem(REFRESH_BASKET_KEY);
    };

    private getLastUpdated = (): number | null => {
        const timestamp = localStorage.getItem(this.TIMESTAMP_KEY);
        return timestamp ? parseInt(timestamp, 10) : null;
    };
}

export const refreshBasketStorage = new RefreshBasketStorage();