import {AppContext} from '@app/context';
import {ToastVisibilityContext} from '@app/Providers/CustomToasterProvider/ToastVisibilityContext';
import {Text, useToaster} from '@gravity-ui/uikit';
import {useEffect, useRef, useContext} from 'react';
import {Link} from 'react-router-dom';

export const useToastBalance = () => {
    const {add, remove} = useToaster();
    const {setCurrentToastName, currentToastName} = useContext(ToastVisibilityContext);
    const toastNameRef = useRef<string | null>(null);

    const showBlockToast = (isOrderAndShipmentBlocked: boolean) => {
        setCurrentToastName('block_balance_toast');

        const toastName = 'block_balance_toast';
        toastNameRef.current = toastName;

        const content = isOrderAndShipmentBlocked ? (
            <>
                <div>
                    <Text variant="subheader-2">
                        Возможность оформления заказа и отгрузка заблокированы
                    </Text>
                </div>
                <div>
                    <Text variant="body-2">
                        Пожалуйста, обратитесь к{' '}
                        <Link to="/profile/contacts" style={{textDecoration: 'none'}}>
                            <span
                                style={{
                                    color: '#000000D9',
                                    borderBottom: '1px solid #000000D9'
                                }}
                            >
                                вашему менеджеру
                            </span>
                        </Link>
                    </Text>
                </div>
            </>
        ) : (
            <>
                <div>
                    <Text variant="subheader-2">Возможность оформления заказа заблокирована</Text>
                </div>
                <div>
                    <Text variant="body-2">
                        Пожалуйста, обратитесь к{' '}
                        <Link to="/profile/contacts" style={{textDecoration: 'none'}}>
                            <span
                                style={{
                                    color: '#000000D9',
                                    borderBottom: '1px solid #000000D9'
                                }}
                            >
                                вашему менеджеру
                            </span>
                        </Link>
                    </Text>
                </div>
            </>
        );

        add({
            name: toastName,
            content: content,
            theme: 'danger',
            autoHiding: false,
            isClosable: false
        });

        updateToasterStyles(isOrderAndShipmentBlocked);
    };

    const showWarningToast = () => {
        setCurrentToastName('warning_balance_toast');

        const toastName = 'warning_balance_toast';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            content: (
                <>
                    <div>
                        <Text variant="subheader-2">Отгрузка товаров заблокирована</Text>
                    </div>
                    <div>
                        <Text variant="body-2">
                            Пожалуйста, оплатите задолженность или обратитесь к{' '}
                            <Link to="/profile/contacts" style={{textDecoration: 'none'}}>
                                <span
                                    style={{
                                        color: '#000000D9',
                                        borderBottom: '1px solid #000000D9'
                                    }}
                                >
                                    вашему менеджеру
                                </span>
                            </Link>
                        </Text>
                    </div>
                </>
            ),
            theme: 'warning',
            autoHiding: false,
            isClosable: false
        });
    };

    const updateToasterStyles = (isOrderAndShipmentBlocked: boolean) => {
        const root = document.documentElement;

        if (currentToastName === 'block_balance_toast') {
            root.style.setProperty('--g-toaster-top', '180px');
            root.style.setProperty(
                '--g-toaster-width',
                isOrderAndShipmentBlocked ? '528px' : '442px'
            );
            root.style.setProperty(
                '--g-toaster-content-width',
                isOrderAndShipmentBlocked ? '465px' : '400px'
            );
        }

        if (currentToastName === 'warning_balance_toast') {
            root.style.setProperty('--g-toaster-top', '180px');
            root.style.setProperty('--g-toaster-width', '640px');
            root.style.setProperty('--g-toaster-content-width', '600px');
        }
    };

    // Для изменения позиции и ширины тостера
    useEffect(() => {
        if (currentToastName === 'block_balance_toast') {
            const isOrderAndShipmentBlocked =
                toastNameRef.current === 'block_balance_toast_with_shipment_block';
            updateToasterStyles(isOrderAndShipmentBlocked);
        } else if (currentToastName === 'warning_balance_toast') {
            updateToasterStyles(false);
        }
    }, [currentToastName]);

    useEffect(() => {
        // удаляем тост при переходе на другую страницу
        return () => {
            if (toastNameRef.current) {
                remove(toastNameRef.current);
                toastNameRef.current = null;
            }
        };
    }, [remove]);

    return {
        showBlockToast,
        showWarningToast
    };
};
