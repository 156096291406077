import React from 'react';
import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';

import NotFoundSvg from '@assets/search-not-found.svg';

import './NotFound.scss';

const b = block('not-found');

export interface NotFoundProps {
    showClearFilters?: boolean;
    searchText?: string;
}

export const NotFound: React.FC<NotFoundProps> = (props) => {
    const shouldShowSearchText = props.searchText && props.searchText.trim() !== '';

    return (
        <div className={b()}>
            <img className={b('image')} src={NotFoundSvg}></img>
            {props.showClearFilters || !shouldShowSearchText ? (
                <Text variant='subheader-2'>По вашему запросу ничего не нашлось.</Text>
            ) : (
                <Text variant='body-2'>
                    По вашему запросу «<Text variant='subheader-2'>{props.searchText}</Text>» ничего не нашлось.
                </Text>
            )}
            {props.showClearFilters ? <Text>Попробуйте изменить настройки фильтров</Text> : <></>}
        </div>
    );
};
