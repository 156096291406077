import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {ChevronUp, Eye} from '@gravity-ui/icons';

import './AlertVisiblePercent.scss';

const b = block('alert-percent');

export const AlertVisiblePercent: React.FC = () => {
    const [showAlert, setShowAlert] = useState(true);

    const handleContextToggle = () => {
        setShowAlert(prevState => !prevState);
    };

    return (
        <div className={b()}>
            <Icon data={Eye} size={18} className={b('icon')}></Icon>
            <div className={b('header-container')} onClick={handleContextToggle}>
                <Text variant='subheader-2'>Отображение наценки</Text>
                <div className={b('chevron', {'turn': showAlert})}>
                    <Icon data={ChevronUp} size={20}></Icon>
                </div>
            </div>
            <div className={b('context', {hidden: showAlert})}>
                <p><b>В поиске:</b> цены будут показаны с учетом наценки. Переключатель также появится в фильтрах результата поиска.</p>
                <p><b>В корзине:</b> цены будут показаны с учетом наценки. При оформлении заказа будет отображена базовая цена товара без наценки.</p>
            </div>
        </div>
    );
};
