import './Rating.scss';
import React from 'react';
import block from 'bem-cn-lite';
import {Popover, Progress} from '@gravity-ui/uikit';
import {top} from '@popperjs/core';

const b = block('rating');

export interface RatingProps {
    value: number;
    className: string;
    priceList?: string;
}

export const Rating: React.FC<RatingProps> = ({className, value, priceList}) => {

    const rank = (value: number): string => {
        if (!value) {
            return '—';
        } else return `${value} %`;
    };

    const progressValue = value === 0 ? NaN : value;
    const progressText = rank(value);

    return (
        <div className={b(null, className)}>
            <Popover
                content={priceList}
                placement={top}
                openOnHover={false}
                tooltipClassName={b('tooltip-content')}
                disabled={!priceList}
                delayOpening={0}
                delayClosing={0}
            >
                <Progress
                    className={b('progress')}
                    size="m"
                    value={progressValue}
                    text={progressText}
                    colorStops={[
                        {theme: 'danger', stop: 44},
                        {theme: 'warning', stop: 84},
                        {theme: 'success', stop: 100}
                    ]}
                ></Progress>
            </Popover>
        </div>
    );
};
