import React from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {Box} from '@gravity-ui/icons';
import {BalanceData} from '@features/balance/interfaces/BalanceData';
import {parseAmount} from '@features/balance/utils/parseAmount';

import './RetailBalanceCard.scss';

const b = block('retail-balance-card');

interface RetailBalanceCardProps {
    balanceData: BalanceData;
}

export const RetailBalanceCard: React.FC<RetailBalanceCardProps> = ({balanceData}) => {
    const isOrdersProgress = parseAmount(balanceData.orders.inProgress) > 0;
    const isOrdersReady = parseAmount(balanceData.orders.ready) > 0;

    return (
        <div className={b()}>
            <Icon data={Box} width={32} height={32} className={b('icon')} />

            <div className={b('current-orders')}>
                <Text variant="body-2" color="secondary">
                    Текущие заказы
                </Text>
                <Text variant="header-1">{balanceData.orders.unpaid}</Text>
            </div>

            <div className={b('orders-status')}>
                <div className={b('status')}>
                    <Text variant="body-2" color={isOrdersProgress ? 'secondary' : 'hint'}>
                        В том числе в работе
                    </Text>
                    <Text variant="header-1" color={isOrdersProgress ? 'complementary' : 'hint'}>
                        {balanceData.orders.inProgress}
                    </Text>
                </div>
                <div className={b('status')}>
                    <Text variant="body-2" color={isOrdersReady ? 'secondary' : 'hint'}>
                        Готовы к выдаче
                    </Text>
                    <Text variant="header-1" color={isOrdersReady ? 'complementary' : 'hint'}>
                        {balanceData.orders.ready}
                    </Text>
                </div>
            </div>
        </div>
    );
};
