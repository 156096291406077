import {useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Icon, Checkbox} from '@gravity-ui/uikit';
import {House, FileText} from '@gravity-ui/icons';
import {AppContext} from '@app/context';

import './UserAddress.scss';

const b = block('user-address-block');

export const UserAddress: React.FC = () => {
    const {state: {clientDetails}} = useContext(AppContext);

    return (<>
        <div className={b()}>
            <Text variant='header-1'>Компания</Text>
            <Text className={b('content')} variant='body-2'>
                <Icon className={b('icon')} data={FileText} />{clientDetails?.name || ''}</Text>
            <Text className={b('content')} variant='body-2'>
                <Icon className={b('icon')} data={House} />{clientDetails?.address || ''}</Text>
            <Checkbox size='l' className={b('checkbox')} checked={clientDetails?.isPickup || undefined} disabled><span>Самовывоз</span></Checkbox>
        </div>
        {/* {clients.map((client, index) => (
            <div className={b()} key={index}>
                <Text variant='header-1'>Компания</Text>
                <Text className={b('paragraph')} variant='body-2'>
                    <Icon className={b('icon')} data={FileText} />{client.name}</Text>
                <Text className={b('paragraph')} variant='body-2'>
                    <Icon className={b('icon')} data={House} />{client.address}</Text>
                <Checkbox size='l' className={b('checkbox')} checked={client.selfpickup} disabled><span>Самовывоз</span></Checkbox>
            </div>
        ))} */}
    </>
    );
};
