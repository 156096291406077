import {BasketItemModel} from '../interfaces';

// Приоритетные поля сортировки по умолчанию
export const defaultSortPriority = ['date', 'price', 'deliveryDays', 'deliveryDate', 'rating', 'quantity'];

// Направление сортировки по умолчанию
export const defaultDirection = new Map<string, number>([
    ['price', 1],
    ['deliveryDays', 1],
    ['deliveryDate', 1],
    ['rating', -1],
    ['quantity', -1],
    ['date', -1]
]);

export function sortItems(
    items: BasketItemModel[] | undefined,
    currentSortPriority: string[],
    sortOption: {property: string; direction: 'asc' | 'desc'} | null,
    defaultDirection: Map<string, number>
) {
    if (!items) return [];

    return items.slice().sort((a, b) => {
        for (const property of currentSortPriority) {
            let aValue = getSortValue(a, property);
            let bValue = getSortValue(b, property);

            let comparison = compareValues(aValue, bValue);

            if (comparison !== 0) {
                let direction: number;
                if (sortOption && sortOption.property === property) {
                    direction = sortOption.direction === 'asc' ? 1 : -1;
                } else {
                    direction = defaultDirection.get(property) || 1;
                }
                return comparison * direction;
            }
        }
        return 0;
    });
}

function getSortValue(item: BasketItemModel, sortField: string) {
    switch (sortField) {
        case 'price':
            return item.price;
        case 'deliveryDays':
            return item.deliveryDays;
        case 'rating':
            return item.rating;
        case 'quantity':
            return item.quantity;
        case 'deliveryDate':
            return item.deliveryDate;
        case 'date':
            return item.createdAt;
        default:
            return 0;
    }
}

function compareValues(a: any, b: any) {
    if (typeof a === 'number' && typeof b === 'number') {
        return a - b;
    }
    if ((a instanceof Date) && (b instanceof Date)) {
        return a.getTime() - b.getTime();
    }
    if (typeof a === 'string' && typeof b === 'string') {
        // Возвращаем число, показывающее порядок строк
        return a.localeCompare(b);
    }
    return 0;
}
