import React from 'react';
import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';

import BalanceErrorPng from '@assets/error-not-data.png';

import './BalanceError.scss';

const b = block('balance-error');

export const BalanceError = () => {
    return (
        <div className={b()}>
            <img src={BalanceErrorPng} alt="error" className={b('image')}></img>

            <div className={b('text')}>
                <Text variant={'subheader-2'}>Данные не загрузились.</Text>
                <Text variant={'body-2'}>
                    Попробуйте{' '}
                    <Text variant={'body-2'} color={'link'} onClick={() => window.location.reload()} className={b('link')}>
                        обновить страницу
                    </Text>
                </Text>
            </div>
        </div>
    );
};