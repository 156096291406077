import React, {SyntheticEvent, useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Checkbox, Popover, Switch, Text, TextInput, Toaster} from '@gravity-ui/uikit';
import {AppContext} from 'app/context';
import {CApi as userGwApi} from '@services/api';
import {useDebounce} from 'use-debounce';
import {PriceLevelSettings} from '@app/priceLevelSettings';

import './LevelPrice.scss';

const b = block('level-price');

const toaster = new Toaster({className: b('profile-toaster')});

export const LevelPrice: React.FC = () => {
    const {state: stateAppContext, emit} = useContext(AppContext);
    const {priceLevelSettings: settings} = stateAppContext;

    //если все значения пустые это будет новый пользователь без данных будет true
    const initFirstStart = !settings.enabled && settings.markupPercentage === 0 || settings.markupPercentage === null && !settings.displayInBasket && !settings.displayInSearchResults;

    const [state, setState] = useState({
        switchBtn: settings.enabled,
        input: settings.markupPercentage === 0 || settings.markupPercentage === null ? '' : `${settings.markupPercentage.toString()} %`,
        checkBoxes: {
            displayInSearchResults: settings.displayInSearchResults,
            displayInBasket: settings.displayInBasket
        },
        showError: !initFirstStart, //Не показывать ошибку при старте,
        hasErrorInput: !settings.markupPercentage,
        hasErrorCheckBox: [
            settings.displayInBasket,
            settings.displayInSearchResults
        ].every(item => !item),
        firstStart: true, //пользователь зашел первый раз на страницу
        showTooltip: initFirstStart, //подсказка
        updateApi: false, //переменная для обновления для обновления
        shouldDisableFields: settings.shouldDisableFields //блокировка полей
    });
    const {
        switchBtn,
        input,
        updateApi,
        showError,
        hasErrorInput,
        hasErrorCheckBox,
        showTooltip,
        firstStart,
        shouldDisableFields,
        checkBoxes: {displayInBasket, displayInSearchResults}
    } = state;

    const [debouncedInput] = useDebounce(input.replace(' %', ''), 500);

    useEffect(() => {
        if (firstStart) {
            setState(prevState => ({
                ...prevState,
                firstStart: false
            }));
        } else if (!hasErrorInput && !hasErrorCheckBox && !switchBtn && !shouldDisableFields) {
            setState(prevState => ({
                ...prevState,
                switchBtn: true
            }));
        } else if (hasErrorInput || hasErrorCheckBox && switchBtn && !shouldDisableFields) {
            setState(prevState => ({
                ...prevState,
                switchBtn: false,
                updateApi: true
            }));
            showToaster('disable');
        } else if (!hasErrorInput && !hasErrorCheckBox && switchBtn) {
            setState(prevState => ({
                ...prevState,
                updateApi: true
            }));
            displayInBasket || displayInSearchResults ? showToaster('enable') : null;
        }
    }, [debouncedInput, displayInBasket, displayInSearchResults, switchBtn]);


    useEffect(() => {
        changeSubmit();
        setState(prevState => ({
            ...prevState,
            updateApi: false
        }));
    }, [updateApi]);

    function validation() {
        if (hasErrorInput || hasErrorCheckBox && !switchBtn) {
            setState(prevState => ({
                ...prevState,
                showError: true
            }));
        }
    }

    function handleClickSwitch() {
        validation();
        if (!hasErrorInput && !hasErrorCheckBox) {
            setState(prevState => ({
                ...prevState,
                firstStart: true,
                switchBtn: !switchBtn,
                shouldDisableFields: switchBtn,
                updateApi: true
            }));
            showToaster(!switchBtn ? 'enable' : 'disable');
        }
    }

    function onFocusInput() {
        validation();
        setState(prevState => ({
            ...prevState,
            input: input.replace(' %', '')
        }));
    }

    function onBlurInput() {
        setState(prevState => ({
            ...prevState,
            input: input ? `${input} %` : ''
        }));
    }

    function onChangeInput(value: string) {
        const valueClear = value.replace(/[\s%,бю]/g, (match) => {
                switch (match) {
                    case ' %':
                        return '';
                    case ',':
                        return '.';
                    case 'б':
                    case 'ю':
                        return '.';
                    default:
                        return match;
                }
            }
        );

        const regex = /^\d*\.?,?б?ю?\d?$/;

        if (regex.test(valueClear) && valueClear.length <= 7) {
            //проверим состояние инпута
            const checkInput = !valueClear || valueClear.length === 0;

            setState(prevState => ({
                ...prevState,
                input: valueClear,
                hasErrorInput: checkInput,
                showTooltip: checkInput
            }));
        }
    }

    function handleClickCheckbox(e: SyntheticEvent) {
        const id = e.currentTarget.id as keyof PriceLevelSettings;

        if (id === 'displayInSearchResults' || id === 'displayInBasket') {

            // Инвертируем состояние выбранного чекбокса
            const newStateCheckBoxes = {
                ...state.checkBoxes,
                [id]: !state.checkBoxes[id]
            };

            // Проверяем, есть ли хотя бы один активный чекбокс
            const isAnyCheckboxActive = Object.values(newStateCheckBoxes).some(value => value);

            // Если все чекбоксы сняты, включаем ошибку и отключаем кнопку
            const hasErrorCheckBox = !isAnyCheckboxActive;

            setState(prevState => ({
                ...prevState,
                hasErrorCheckBox, // Ошибка, если нет активных чекбоксов
                showTooltip: hasErrorCheckBox, // Показываем тултип при ошибке
                checkBoxes: newStateCheckBoxes
            }));

        }
    }

    function changeSubmit() {
        userGwApi().client.settingsUpdate(
            {
                visibleExtraCharge: input === '' ? 0 : parseFloat(input.replace(' %', '')),
                visibleExtraChargeView:
                    Object.entries(state.checkBoxes)
                        .filter(([key, value]) => value)
                        .map(([key, value]) => {
                            switch (key) {
                                case 'displayInSearchResults':
                                    return 'suggest';
                                case 'displayInBasket':
                                    return 'basket';
                                default:
                                    return undefined;
                            }
                        })
                        .filter((item): item is 'suggest' | 'basket' => item !== undefined)
            }, stateAppContext.user?.client
        ).then((response) => {
            emit('updatePriceLevelSettings', {
                settings: {
                    ...settings,
                    enabled: switchBtn,
                    shouldDisableFields: state.shouldDisableFields,
                    displayInBasket: displayInBasket,
                    displayInSearchResults: displayInSearchResults,
                    markupPercentage: parseFloat(input)
                } as PriceLevelSettings
            });
        });
    }

    function showToaster(action: 'enable' | 'disable') {
        enum content {
            enable = 'Настройки применены',
            disable = 'Наценка отключена',
        }

        toaster.add({
            name: action,
            title: action === 'enable' ? content.enable : action === 'disable' ? content.disable : undefined,
            theme: action === 'enable' ? 'success' : 'warning',
            autoHiding: 3000,
            isClosable: false
        });
    }

    return (
        <div className={b()}>
            <div className={b('header-container')}>
                <Text variant="header-1">Уровень цен</Text>
                <Popover
                    disabled={!showTooltip}
                    size="s"
                    placement={'top'}
                    content={'Введите % и выберите, где показывать наценку'}
                    tooltipClassName={b('tooltip')}
                >
                    <Switch onChange={handleClickSwitch} checked={switchBtn} />
                </Popover>
            </div>
            <TextInput
                size="l"
                validationState={showError && hasErrorInput ? 'invalid' : undefined}
                errorPlacement={'inside'}
                errorMessage="Error message"
                className={b('input')}
                placeholder="введите % наценки"
                value={input}
                onUpdate={onChangeInput}
                onFocus={() => onFocusInput()}
                onBlur={() => onBlurInput()}
                disabled={shouldDisableFields}
            />
            <Text variant="body-2" className={b('paragraph')}>Отображать наценку в разделах:</Text>
            <Checkbox
                size="l"
                className={b('checkbox', {invalid: showError && hasErrorCheckBox})}
                checked={displayInSearchResults}
                onChange={handleClickCheckbox}
                id="displayInSearchResults"
                disabled={shouldDisableFields}
            >
                <span>результаты поиска</span></Checkbox>
            <Checkbox
                size="l"
                className={b('checkbox', {invalid: showError && hasErrorCheckBox})}
                checked={displayInBasket}
                onChange={handleClickCheckbox}
                id="displayInBasket"
                disabled={shouldDisableFields}
            >
                <span>корзина</span>
            </Checkbox>
        </div>
    );
};