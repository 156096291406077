import React, {SyntheticEvent, useState, useContext, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import block from 'bem-cn-lite';
import {Button, Icon} from '@gravity-ui/uikit';
import {
    Person,
    House,
    Gear,
    Handset,
    Key,
    ArrowRightFromSquare
} from '@gravity-ui/icons';
import {AppContext} from '../../../../app/context';
import {ModalQuit} from '@components';
import './SideBar.scss';

const b = block('profile-sidebar');
const sizeIcon = 20;

type StateBtn = {
    PersonBtn: boolean,
    AddressBtn: boolean,
    PriceBtn: boolean,
    ContactsBtn: boolean,
}

export const SideBar: React.FC = () => {
    const [showModalQuit, setShowModalQuit] = useState(false);
    const {emit} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [stateBtn, setStateBtn] = useState<StateBtn>({
        PersonBtn: true,
        AddressBtn: false,
        PriceBtn: false,
        ContactsBtn: false,
    });

    useEffect(() => {
        switch (location.pathname.replace('/profile/', '')) {
            case 'user-address':
                toggleActiveBtn('AddressBtn')
                break;
            case 'price-level':
                toggleActiveBtn('PriceBtn')
                break;
            case 'contacts':
                toggleActiveBtn('ContactsBtn')
                break;
        }
    }, [location])

    function handleClickPerson(event: SyntheticEvent) {
        toggleActiveBtn(event.currentTarget.id)
        navigate('person-info');
    }
    function handleClickAddress(event: SyntheticEvent) {
        toggleActiveBtn(event.currentTarget.id)
        navigate('user-address');
    }
    function handleClickPrice(event: SyntheticEvent) {
        toggleActiveBtn(event.currentTarget.id)
        navigate('price-level');
    }
    function handleClickContacts(event: SyntheticEvent) {
        toggleActiveBtn(event.currentTarget.id)
        navigate('contacts');
    }

    function toggleActiveBtn(id: string) {
        if (id in stateBtn) {
            const newState = Object.keys(stateBtn).reduce((acc, key) => {
                acc[key as keyof StateBtn] = key === id;
                return acc;
            }, {} as StateBtn)
            setStateBtn(newState);
        }
        else console.debug('не правильно указан id в кнопке')
    }

    //TODO «Изменить пароль» в MVP не используется
    function handleClickKey() { }

    function handleClickLogout() {
        setShowModalQuit((preState) => !preState)
    }

    return (
        <div className={b()}>
            <div className={b('menu-items')}>
                <Button className={b('button', {primary: true})} size='xl'
                    onClick={(event) => handleClickPerson(event)}
                    selected={stateBtn.PersonBtn}
                    id='PersonBtn'>
                    <Icon data={Person} size={sizeIcon} />
                    Персональные данные
                </Button>
                <Button className={b('button', {primary: true})} size='xl'
                    onClick={(event) => handleClickAddress(event)}
                    selected={stateBtn.AddressBtn}
                    id='AddressBtn'>
                    <Icon data={House} size={sizeIcon} />
                    Мои адреса
                </Button>
                <Button className={b('button', {primary: true})} size='xl'
                    onClick={(event) => handleClickPrice(event)}
                    selected={stateBtn.PriceBtn}
                    id='PriceBtn'>
                    <Icon data={Gear} size={sizeIcon} />
                    Уровень цен</Button>
                <Button className={b('button', {primary: true})} size='xl'
                    onClick={(event) => handleClickContacts(event)}
                    selected={stateBtn.ContactsBtn}
                    id='ContactsBtn'>
                    <Icon data={Handset} size={sizeIcon} />
                    Контакты
                </Button>
            </div>
            <div className={b('menu-footer')}>
                <Button className={b('button', {small: true})} size='xl' onClick={handleClickKey} disabled>
                    <Icon data={Key} size={sizeIcon} />
                    Изменить пароль
                </Button>
                <Button className={b('button', {logout: true, small: true})} size='xl' onClick={handleClickLogout}>
                    <Icon data={ArrowRightFromSquare} size={sizeIcon} />
                    Выйти из профиля
                </Button>
            </div>
            <ModalQuit visibility={showModalQuit}
                stateModal={setShowModalQuit}
                positiveAction={() => emit('logout', {redirectTo: '/'})} />
        </div>
    );
};
