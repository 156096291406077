import React, {useContext, useRef} from 'react';
import block from 'bem-cn-lite';
import './Proposal.scss';
import {DeliveryDate, DeliveryDays, Price, ProposalBadges, Quantity, Rating} from '@components';
import {BasketControls} from '../BasketControls';
import {ProposalModel} from '@features/search/interfaces';
import {useInView} from 'react-intersection-observer';
import {AppContext} from 'app/context';
import {getAdjustedPrice} from '@utils/getAdjustedPrice';

const b = block('proposal');

export interface ProposalProps {
    proposal: ProposalModel;
}

export const Proposal: React.FC<ProposalProps> = ({proposal}) => {
    const {state} = useContext(AppContext);

    const isDebugLoggingEnabled = false;
    const wasHighlighted = useRef(false);
    const {ref} = useInView({
        onChange: (eInView, eEntry) => {
            isDebugLoggingEnabled &&
                console.log(
                    `Item::useInView::onChange ${JSON.stringify({
                        useInView: eInView,
                        target: eEntry.target.id
                    })}`
                );
            if (eInView && proposal.generation > 0) {
                if (wasHighlighted.current) return;
                eEntry.target.classList.add('proposal__proposal_gen1');
                wasHighlighted.current = true;
                setTimeout(() => {
                    eEntry.target.classList.remove('proposal__proposal_gen1');
                }, 5000);
            }
        }
    });

    const quantity = proposal.basketItem?.itemsCnt || proposal.multiplicity;

    const adjustedPrice = getAdjustedPrice(proposal.price, state.priceLevelSettings, 'search');

    return (
        <div ref={ref} className={b('proposal')}>
            <ProposalBadges
                className={b('icons')}
                isOfficialDealer={proposal.isOfficialDealer}
                isReturnPossible={proposal.isReturnPossible}
            />
            <Rating className={b('rating')} priceList={proposal.priceList} value={proposal.rating} />
            <Quantity className={b('quantity')} value={proposal.quantity} />
            <DeliveryDays className={b('delivery-days')} value={proposal.deliveryDays} />
            <DeliveryDate className={b('delivery-date')} value={proposal.deliveryDate} />
            <Price className={b('price')} value={adjustedPrice} />

            <BasketControls
                className={b('basket-controls')}
                quantity={quantity}
                multiplicity={proposal.multiplicity}
                maxQuantity={proposal.quantity}
                proposalId={proposal.id}
                basketItemId={proposal.basketItem?.id}
            />
        </div>
    );
};
