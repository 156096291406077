import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Modal, Text, Icon, Button} from '@gravity-ui/uikit';
import {Xmark} from '@gravity-ui/icons';
import {priceLevelSettingsStorage} from 'app/priceLevelSettings';
import './ModalQuit.scss';

const b = block('modal-quit');

interface ModalQuitProps {
    visibility: boolean;
    stateModal: (value: boolean) => void;
    positiveAction: () => void;
}

export const ModalQuit: React.FC<ModalQuitProps> = ({visibility, stateModal, positiveAction}) => {
    return (
        <Modal open={visibility} className={b('modal')} onClose={() => stateModal(false)}>
            <Text className={b('header')} variant='subheader-3'>Выйти из профиля?
                <div onClick={() => stateModal(false)}>
                    <Icon data={Xmark} size={16} />
                </div>
            </Text>
            <Button className='modal-btn' size='m' view="flat" onClick={() => stateModal(false)}>
                Отмена
            </Button>
            <Button className='modal-btn' size='m' view="flat" onClick={() => {
                positiveAction();
                priceLevelSettingsStorage.clear();
            }}>
                Выйти
            </Button>
        </Modal>
    );
};