import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {TriangleExclamation, ChevronUp} from '@gravity-ui/icons';
import './AlertImportant.scss';

const b = block('alert-important');

export const AlertImportant: React.FC = () => {

    return (
        <div className={b()}>
            <Icon data={TriangleExclamation} size={18} className={b('icon')}></Icon>
            <div className={b('header-container')}>
                <Text variant='subheader-2'>Важно</Text>
            </div>
            <div className={b('context')}>
                <ul>
                    <li>при первом входе в сервис наценка по умолчанию отключена.</li>
                    <li>последние заданные пользователем настройки сохраняются автоматически и применяются при каждом следующем входе.</li>
                </ul>
            </div>
        </div>
    );
};
