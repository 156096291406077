import {useContext, useState} from 'react';
import {OrderPost} from '@services/user-gw';
import {CApi as userGwApi} from '@services/api';
import {useToast} from '@hooks';
import {AppContext} from 'app/context';

export const useCreateOrder = (resetOrderCreated?: () => void) => {
    const {state, emit} = useContext(AppContext);
    const {basket} = state;
    const {
        showCreatedOrderToast,
        showMakeOrderErrorToast,
        showBlockOrderToast,
        showNotEnoughFinanceOrderToast,
        showNoticeCreatedOrderToast
    } = useToast(resetOrderCreated);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const createOrder = async (): Promise<boolean> => {
        setIsLoading(true);
        setLoadingText('Оформляем заказ...');
        try {
            const basketItems: string[] = basket.items
                .filter((item) => item.checked)
                .map((item) => item.id);

            if (basketItems.length === 0) {
                showMakeOrderErrorToast();
                setIsLoading(false);
                return false;
            }

            const orderData: OrderPost = {
                basketItems
            };

            const response = await userGwApi().order.orderCreate(orderData);
            console.log('Заказ создан:', response);

            const orderTotal = response.data.finance?.orderTotal;
            const available = response.data.finance?.available;
            const expiredSum = response.data.finance?.debt?.expired;
            const expiredDays = response.data.finance?.debt?.expiredDays;
            const requiredPayment = response.data.finance?.requiredPayment;

            // Стоп-заказ
            if (response.status === 403) {
                showBlockOrderToast();
                return false;
            }

            // Недостаточно средств
            if (
                response.data?.success === false &&
                response.data?.errors?.includes('finance.available.not_enough')
            ) {
                showNotEnoughFinanceOrderToast(orderTotal, available);
                return false;
            }

            if (response.data?.warnings?.includes('finance.debt.expired_exists')) {
                // Сценарий с просроченной задолженностью
                showNoticeCreatedOrderToast(
                    expiredSum,
                    expiredDays,
                    requiredPayment,
                    'debt_exists'
                );
            } else if (response.data?.warnings?.includes('finance.required_payment')) {
                // Сценарий с необходимостью пополнения баланса
                showNoticeCreatedOrderToast(
                    expiredSum,
                    expiredDays,
                    requiredPayment,
                    'required_payment'
                );
            } else {
                showCreatedOrderToast(orderTotal, available);
            }

            const updatedItems = basket.items.filter((item) => !item.checked);
            emit('updateBasket', {
                basket: {
                    items: updatedItems
                }
            });

            return true;
        } catch (error) {
            const typedError = error as {status?: number; response?: {status?: number}};

            // Обрабатываем 403 ошибку
            if (typedError?.status === 403 || typedError?.response?.status === 403) {
                showBlockOrderToast();
                return false;
            }

            console.error('Ошибка при создании заказа:', error);
            throw error; // Перебрасываем все остальные ошибки
        } finally {
            setIsLoading(false);
        }
    };

    return {createOrder, isLoading, loadingText};
};
