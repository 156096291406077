import {useEffect, useRef, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useToaster, ButtonView, Button, Text} from '@gravity-ui/uikit';
import {CircleRuble, TriangleExclamation, Trolley} from '@gravity-ui/icons';
import {ToastVisibilityContext} from 'app/Providers/CustomToasterProvider/ToastVisibilityContext';
import {AppContext} from 'app/context';

export const useToast = (resetOrderCreated?: () => void) => {
    const {add, has, remove} = useToaster();
    const {isToasterVisible, setIsToasterVisible, setCurrentToastName, currentToastName} =
        useContext(ToastVisibilityContext);
    const toastNameRef = useRef<string | null>(null);
    const {state} = useContext(AppContext);

    const balanceRestrictionType = state.clientDetails?.balance.balanceRestrictionType;

    const showToast = (
        type: 'success' | 'danger' | 'warning',
        message: string,
        options?: {isPriceDecreasedOnly?: boolean; isError?: boolean}
    ) => {
        setIsToasterVisible(true);

        const {isPriceDecreasedOnly = false, isError = false} = options || {};

        const additionalContent =
            !isError &&
            (type === 'warning' || type === 'danger') && !isPriceDecreasedOnly
                ? 'Вы можете найти замену, удалить товары из корзины или продолжить оформление.'
                : null;

        const actions =
            (type === 'warning' || type === 'danger') && !isPriceDecreasedOnly
                ? [
                      {
                          label: 'Ок, понятно',
                          onClick: () => {
                              console.log('Action clicked');
                              setIsToasterVisible(false);
                          },
                          view: 'normal-contrast' as ButtonView,
                          removeAfterClick: true
                      }
                  ]
                : [];

        let toastName = `basket-check-${type}`;
        if (isPriceDecreasedOnly) {
            toastName = `basket-check-price-decreased`;
        }

        setCurrentToastName(toastName);
        toastNameRef.current = toastName;

        let theme = type;
        let toastParams: any = {
            name: toastName,
            title: message,
            content: additionalContent,
            theme,
            autoHiding: type === 'success' ? 5000 : false,
            actions,
            isClosable: true
        };

        if (isPriceDecreasedOnly) {
            // Для случая, когда только цены уменьшились
            toastParams.theme = 'success';
            toastParams.autoHiding = false;
            toastParams.actions = [
                {
                    label: 'Ок, понятно',
                    onClick: () => setIsToasterVisible(false),
                    view: 'normal-contrast' as ButtonView,
                    removeAfterClick: true
                }
            ];
            toastParams.renderIcon = () => <TriangleExclamation width={24} height={24} />;
        }

        add(toastParams);

        if (type === 'success' && !isPriceDecreasedOnly) {
            setTimeout(() => {
                setIsToasterVisible(false);
            }, 5000);
        }
    };

    const showAddToCartErrorToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('add_to_basket_error');

        const toastName = 'add_to_basket_error';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Произошла ошибка при добавлении товара в корзину',
            content: (
                <>
                    <Text variant="body-2">Попробуйте еще раз.</Text>
                    <Button
                        size="l"
                        view="normal-contrast"
                        onClick={() => {
                            remove(toastName);
                            setIsToasterVisible(false);
                        }}
                        style={{marginTop: '8px', width: '100%'}}
                    >
                        Ок, попробую еще раз
                    </Button>
                </>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true
        });
    };

    const showMakeOrderErrorToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('make_order_error');

        const toastName = 'make_order_error';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Нет выбранных товаров для оформления заказа',
            content: (
                <>
                    <Button
                        size="l"
                        view="normal-contrast"
                        onClick={() => {
                            remove(toastName);
                            setIsToasterVisible(false);
                        }}
                        style={{marginTop: '8px', width: '100%'}}
                    >
                        Ок, понятно
                    </Button>
                </>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true
        });
    };

    const showCreatedOrderToast = (
        orderTotal: number | undefined,
        available: number | undefined
    ) => {
        setIsToasterVisible(true);
        setCurrentToastName('create-order');

        const toastName = 'create-order';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: `Заказ успешно оформлен на сумму ${orderTotal?.toLocaleString('ru-RU')} ₽`,
            content: (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '8px',
                            borderRadius: '8px',
                            background: 'rgba(0, 0, 0, 0.05)'
                        }}
                    >
                        <Text variant="body-2" color="complementary">
                            {balanceRestrictionType === 'retail'
                                ? 'Доступная сумма'
                                : 'Доступно для нового'}
                            <br />
                            {balanceRestrictionType === 'retail' ? 'на балансе:' : 'заказа:'}
                        </Text>
                        <Text variant="body-2" color="primary">
                            {available?.toLocaleString('ru-RU')} ₽
                        </Text>
                    </div>

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Link to="/orders">
                            <Button
                                size="l"
                                view="normal-contrast"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{marginTop: '8px', width: '100%'}}
                            >
                                Перейти в заказы
                            </Button>
                        </Link>
                        <Button
                            size="l"
                            view="outlined"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                                if (resetOrderCreated) {
                                    resetOrderCreated();
                                }
                            }}
                            style={{marginTop: '8px', width: '100%'}}
                        >
                            Ок, понятно
                        </Button>
                    </div>
                </div>
            ),
            theme: 'success',
            autoHiding: false,
            isClosable: true
        });
    };

    const showBlockOrderToast = () => {
        setIsToasterVisible(true);
        setCurrentToastName('block_order');

        const toastName = 'block_order';
        toastNameRef.current = toastName;

        add({
            name: toastName,
            title: 'Мы заблокировали возможность оформления заказа',
            content: (
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Text variant="body-2">Пожалуйста, обратитесь к вашему менеджеру</Text>

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Link to="/profile/contacts">
                            <Button
                                size="l"
                                view="normal-contrast"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{width: '100%'}}
                            >
                                Показать контакты
                            </Button>
                        </Link>
                        <Button
                            size="l"
                            view="outlined"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                            }}
                            style={{width: '100%'}}
                        >
                            Ок, понятно
                        </Button>
                    </div>
                </div>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true
        });
    };

    const showNotEnoughFinanceOrderToast = (
        orderTotal: number | undefined,
        available: number | undefined
    ) => {
        setIsToasterVisible(true);
        setCurrentToastName('not_enough_finance_order');

        const toastName = 'not_enough_finance_order';
        toastNameRef.current = toastName;

        const missingAmount = Math.max((orderTotal ?? 0) - (available ?? 0), 0);

        add({
            name: toastName,
            title: 'Недостаточно средств на счете. Оформление заказа невозможно.',
            content: (
                <div style={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                    <Text variant="body-2">
                        Пожалуйста, пополните баланс или отправьте в заказ только часть товаров.
                    </Text>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                            padding: '8px',
                            borderRadius: '8px',
                            background: 'rgba(0, 0, 0, 0.05)'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                Выбрано товаров:
                            </Text>
                            <Text variant="body-2" color="primary">
                                {orderTotal?.toLocaleString('ru-RU')} ₽
                            </Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                {balanceRestrictionType === 'retail'
                                    ? 'Баланс'
                                    : 'Доступная сумма для заказа:'}
                            </Text>
                            <Text variant="body-2" color="primary">
                                {available?.toLocaleString('ru-RU')} ₽
                            </Text>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                Не хватает:
                            </Text>
                            <Text variant="body-2" color="danger">
                                -{missingAmount.toLocaleString('ru-RU')} ₽
                            </Text>
                        </div>
                    </div>

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Button
                            size="l"
                            view="normal-contrast"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                            }}
                            style={{width: '100%'}}
                        >
                            Ок, понятно
                        </Button>

                        <Link to="/profile/contacts">
                            <Button
                                size="l"
                                view="outlined"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{width: '100%'}}
                            >
                                Показать контакты
                            </Button>
                        </Link>
                    </div>
                </div>
            ),
            theme: 'danger',
            autoHiding: false,
            isClosable: true,
            renderIcon: () => <CircleRuble width={24} height={24} />
        });
    };

    const showNoticeCreatedOrderToast = (
        expiredSum: number,
        expiredDays: number,
        requiredPayment: number | undefined,
        scenario: 'debt_exists' | 'required_payment'
    ) => {
        setIsToasterVisible(true);
        setCurrentToastName('notice_created_order');

        const toastName = 'notice_created_order';
        toastNameRef.current = toastName;

        const title =
            scenario === 'required_payment'
                ? `Заказ принят.\nДля получения заказа пополните баланс на сумму: ${requiredPayment?.toLocaleString('ru-RU')} ₽`
                : 'Заказ принят.\nДля его получения, пожалуйста, оплатите задолженность.';

        add({
            name: toastName,
            title,
            content: (
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    {scenario === 'debt_exists' && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '8px',
                                borderRadius: '8px',
                                background: 'rgba(0, 0, 0, 0.05)'
                            }}
                        >
                            <Text variant="body-2" color="primary">
                                Просроченная
                                <br />
                                задолженность:
                            </Text>
                            <Text variant="body-2" color="danger" style={{textAlign: 'right'}}>
                                -{expiredSum.toLocaleString('ru-RU')} ₽
                                <br />
                                {expiredDays} дней
                            </Text>
                        </div>
                    )}

                    <div
                        style={{
                            marginTop: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '8px'
                        }}
                    >
                        <Link to="/orders">
                            <Button
                                size="l"
                                view="normal-contrast"
                                onClick={() => {
                                    remove(toastName);
                                    setIsToasterVisible(false);
                                }}
                                style={{marginTop: '8px', width: '100%'}}
                            >
                                Перейти в заказы
                            </Button>
                        </Link>
                        <Button
                            size="l"
                            view="outlined"
                            onClick={() => {
                                remove(toastName);
                                setIsToasterVisible(false);
                                if (resetOrderCreated) {
                                    resetOrderCreated();
                                }
                            }}
                            style={{marginTop: '8px', width: '100%'}}
                        >
                            Ок, понятно
                        </Button>
                    </div>
                </div>
            ),
            theme: 'warning',
            autoHiding: false,
            isClosable: true,
            renderIcon: () => <Trolley width={24} height={24} />
        });
    };

    // Отслеживаем удаление тоста
    useEffect(() => {
        if (!toastNameRef.current) return;

        const interval = setInterval(() => {
            if (!has(toastNameRef.current!)) {
                setIsToasterVisible(false);
                clearInterval(interval);
                toastNameRef.current = null; // Сбрасываем имя тоста после его закрытия

                if (resetOrderCreated) {
                    resetOrderCreated();
                }
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, [has, setIsToasterVisible, isToasterVisible]);

    // Для изменения позиции и ширины тостера
    useEffect(() => {
        const root = document.documentElement;

        if (currentToastName === 'basket-check-success') {
            root.style.setProperty('--g-toaster-top', '180px');
        } else if (currentToastName) {
            root.style.setProperty('--g-toaster-top', '50%');
        }

        if (currentToastName === 'not_enough_finance_order') {
            root.style.setProperty('--g-toaster-width', '442px');
            root.style.setProperty('--g-toaster-content-width', '346px');
        } else if (currentToastName) {
            root.style.setProperty('--g-toaster-width', '400px');
            root.style.setProperty('--g-toaster-content-width', '304px');
        }
    }, [currentToastName]);

    useEffect(() => {
        // удаляем тост при переходе на другую страницу
        return () => {
            if (toastNameRef.current) {
                remove(toastNameRef.current);
                toastNameRef.current = null;
                setIsToasterVisible(false);
            }
        };
    }, [remove]);

    return {
        showToast,
        isToasterVisible,
        showAddToCartErrorToast,
        showCreatedOrderToast,
        showMakeOrderErrorToast,
        showBlockOrderToast,
        showNotEnoughFinanceOrderToast,
        showNoticeCreatedOrderToast
    };
};
