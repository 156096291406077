import './AuxControls.scss';
import React, {useState} from 'react';
import block from 'bem-cn-lite';
import {Button} from '@gravity-ui/uikit';
import {Magnifier, TrashBin} from '@gravity-ui/icons';
import {BasketTrashModal} from '@features/basket/components/BasketTrashModal';
import {TooltipButton} from './TooltipButton/TooltipButton';
import {BasketItemModel} from '@features/basket/interfaces';
import {CommentPopoverButton} from './CommentPopoverButton/CommentPopoverButton';
import {useBasket} from '@features/basket/hooks/useBasket';
import {getSearchUrl} from '@utils';

const b = block('aux-controls');

export interface AuxControlsProps {
    className?: string;
    item: BasketItemModel;
}

export const AuxControls: React.FC<AuxControlsProps> = ({className, item}) => {
    const {handleDeleteItem} = useBasket();
    const [isModalOpen, setIsModalOpen] = useState(false);

    /*Изменение цвета иконки при обновлении*/
    let view: 'outlined' | 'outlined-warning' = 'outlined';

    // Проверяем, увеличилась ли цена
    if (item.oldPrice && item.price > item.oldPrice) {
        view = 'outlined-warning';
    }
    // Проверяем, изменилось ли количество
    else if (item.quantityDecreased) {
        view = 'outlined-warning';
    }
    /*Изменение цвета иконки при обновлении*/

    const handleDeleteClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        handleDeleteItem(item.id);
        setIsModalOpen(false);
    };

    const handleSearchClick = () => {
        const url = getSearchUrl(item.article, item.brandName, false);
        window.open(url, '_blank');
    };

    return (
        <div className={b(null, className)}>
            <CommentPopoverButton item={item} />

            <TooltipButton
                tooltipContent={
                    <>
                        Поиск замены. <br />
                        Откроется в новом окне
                    </>
                }
                icon={<Magnifier />}
                className={b('button')}
                view={view}
                onClick={handleSearchClick}
            />

            <Button
                view={'outlined-danger'}
                className={b('button')}
                onClick={handleDeleteClick}
            >
                <TrashBin />
            </Button>

            <BasketTrashModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};
