import './CreditLimitCard.scss';
import React, {useContext} from 'react';
import block from 'bem-cn-lite';
import {Icon, Text} from '@gravity-ui/uikit';
import {Database, LayoutHeader} from '@gravity-ui/icons';
import {AppContext} from 'app/context';
import {BalanceData} from '@features/balance/interfaces/BalanceData';
import {parseAmount} from '@features/balance/utils/parseAmount';
import {formatDate} from '@utils';

const b = block('credit-limit-card');

interface CreditLimitCardProps {
    balanceData: BalanceData;
}

export const CreditLimitCard: React.FC<CreditLimitCardProps> = ({balanceData}) => {
    const {state} = useContext(AppContext);
    const balanceRestrictionType = state.clientDetails?.balance.balanceRestrictionType;

    const isPaidTomorrow = parseAmount(balanceData.upcomingPayments?.tomorrow) > 0;
    const isClosestAmount = parseAmount(balanceData.upcomingPayments?.closest.amount) > 0;

    return (
        <div className={b()}>
            <div className={b('available-amount-card')}>
                <Icon data={LayoutHeader} width={32} height={32} className={b('icon')} />
                <div className={b('container')}>
                    <Text variant="body-2" color="secondary">
                        Доступно для заказа
                    </Text>
                    <Text variant="display-1">{balanceData.availableForOrder}</Text>
                </div>
            </div>

            {balanceRestrictionType &&
                ['post_payment', 'prepaid_shipment'].includes(balanceRestrictionType) && (
                    <div className={b('paymant-card')}>
                        <Icon data={Database} width={32} height={32} className={b('icon')} />
                        <div className={b('payment')}>
                            <Text variant="body-2" color={isPaidTomorrow ? 'secondary' : 'hint'}>
                                Минимальная оплата на завтра
                            </Text>

                            <Text variant="header-1" color={isPaidTomorrow ? 'primary' : 'hint'}>
                                {balanceData.upcomingPayments?.tomorrow}
                            </Text>
                        </div>

                        {!isPaidTomorrow && (
                            <div className={b('payment')}>
                                <Text
                                    variant="body-2"
                                    color={isClosestAmount ? 'secondary' : 'hint'}
                                >
                                    Ближайший платеж
                                </Text>
                                <div className={b('payment-date')}>
                                    <Text
                                        variant="header-1"
                                        color={isClosestAmount ? 'primary' : 'hint'}
                                    >
                                        {balanceData.upcomingPayments?.closest.amount}
                                    </Text>

                                    {balanceData.upcomingPayments?.closest.date && (
                                        <Text variant="body-3" color="complementary">
                                            до{' '}
                                            {formatDate(
                                                balanceData.upcomingPayments.closest.date,
                                                'DD.MM.YY'
                                            )}
                                        </Text>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
        </div>
    );
};
