import {CApi as userGwApi} from '@services/api';
import {ISelectOption} from '@features/orders/interfaces';

export async function getFilterSuggestions(
    filterType: 'status' | 'ref_id' | 'brand_name' | 'article',
    searchText: string
): Promise<ISelectOption[]> {
    let r = await userGwApi().orderSearchFilterValue.orderSearchFilterValueDetail(filterType, {
        q: searchText
    });
    return Object.values(r.data.values).map((x) => ({
        value: x.id,
        label: x.name,
        isSelected: false
    }));
}