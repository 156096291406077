import React, {useState, useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Tooltip} from '@gravity-ui/uikit';
import {Envelope, Copy} from '@gravity-ui/icons';
import ManagerPng from '@assets/avatar-manager.png';
import Whatsapp from '@assets/whatsapp-icon.svg';
import {AppContext} from '@app/context';

import './ContactCard.scss';

const b = block('contact-card');

interface ContactProps {
    children: React.ReactNode;
    copyText: string;
}

const Contact: React.FC<ContactProps> = ({children, copyText}) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    return (
        <Tooltip
            content="Скопировано"
            placement="top-end"
            disabled={!openTooltip}
            openDelay={0}
            contentClassName={b('tooltip')}
            className={b('tooltip-container')}
        >
            <div
                className={b('copy')}
                onClick={() => {
                    setOpenTooltip(true);
                    navigator.clipboard.writeText(copyText);
                    setTimeout(() => setOpenTooltip(false), 1000);
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
};

const ContactCard: React.FC = () => {
    const {state: {clientDetails}} = useContext(AppContext);

    return (
        <div className={b()}>
            <div className={b('contact-container')}>
                <img src={ManagerPng} alt="Менеджер" />

                <div className={b('contact-info')}>
                    <Text variant="subheader-2">Ваш менеджер:</Text>
                    {
                        clientDetails?.mop ?
                            <>
                                <Text variant="body-2">{clientDetails.mop.name || ''}</Text>

                                <Contact copyText={clientDetails.mop.email || ''}>
                                    <Envelope />
                                    <Text variant="body-1">{clientDetails.mop.email || ''}</Text>
                                </Contact>

                                <div className={b('contact-number')}>
                                    <Contact copyText={clientDetails.mop.phone || ''}>
                                        <Copy />
                                        <Text variant="body-1">{clientDetails.mop.phone || ''}</Text>
                                    </Contact>

                                    <Tooltip
                                        content={
                                            <Text className={b('whatsapp-tooltip-content')}>
                                                Откроется Whatsapp в браузере
                                            </Text>
                                        }
                                        placement="top-end"
                                        contentClassName={b('whatsapp-tooltip')}
                                        className={b('whatsapp-tooltip-container')}
                                        openDelay={0}
                                        closeDelay={0}
                                        disabled={false}
                                    >
                                        <a
                                            href={`https://web.whatsapp.com/send?phone=${clientDetails.mop.phone || ''}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={b('whatsapp-link')}
                                        >
                                            <img src={Whatsapp} alt="Whatsapp" />
                                        </a>
                                    </Tooltip>
                                </div>

                            </>
                            :
                            <Text variant='body-2' className={b('emty-data')}>Нет данных</Text>
                    }
                </div>
            </div>

            <div className={b('separator')}></div>

            <div className={b('contact-container')}>
                <Text variant="subheader-2">
                    Специалист<br />клиентской<br />поддержки:
                </Text>
                {
                    clientDetails?.mrk ?
                        <div className={b('contact-info')}>
                            <Text variant="body-2">{clientDetails.mrk.name || ''}</Text>

                            <Contact copyText={clientDetails.mrk.email || ''}>
                                <Envelope />
                                <Text variant="body-1">{clientDetails.mrk.email || ''}</Text>
                            </Contact>

                            <div className={b('contact-number')}>
                                <Contact copyText={clientDetails.mrk.phone || ''}>
                                    <Copy />
                                    <Text variant="body-1">{clientDetails.mrk.phone || ''}</Text>
                                </Contact>
                                <Tooltip
                                    content={
                                        <Text className={b('whatsapp-tooltip-content')}>
                                            Откроется Whatsapp в браузере
                                        </Text>
                                    }
                                    placement="top-end"
                                    contentClassName={b('whatsapp-tooltip')}
                                    className={b('whatsapp-tooltip-container')}
                                    openDelay={0}
                                    closeDelay={0}
                                    disabled={false}
                                >
                                    <a
                                        href={`https://web.whatsapp.com/send?phone=${clientDetails.mrk.phone || ''}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={b('whatsapp-link')}
                                    >
                                        <img src={Whatsapp} alt="Whatsapp" />
                                    </a>
                                </Tooltip>
                            </div>
                        </div>
                        :
                        <Text variant='body-2' className={b('emty-data')}>Нет данных</Text>
                }
            </div>

            <div className={b('contact-container')}>
                <Text variant="subheader-2">
                    Руководитель <br /> отдела продаж:
                </Text>
                {
                    clientDetails?.rop ?
                        <div className={b('contact-info')}>
                            <Text variant="body-2">{clientDetails.rop.name}</Text>

                            <Contact copyText={clientDetails.rop.email || ''}>
                                <Envelope />
                                <Text variant="body-1">{clientDetails.rop.email || ''}</Text>
                            </Contact>

                            <div className={b('contact-number')}>
                                <Contact copyText={clientDetails.rop.phone || ''}>
                                    <Copy />
                                    <Text variant="body-1">{clientDetails.rop.phone || ''}</Text>
                                </Contact>
                                <Tooltip
                                    content={
                                        <Text className={b('whatsapp-tooltip-content')}>
                                            Откроется Whatsapp в браузере
                                        </Text>
                                    }
                                    placement="top-end"
                                    contentClassName={b('whatsapp-tooltip')}
                                    className={b('whatsapp-tooltip-container')}
                                    openDelay={0}
                                    closeDelay={0}
                                    disabled={false}
                                >
                                    <a
                                        href={`https://web.whatsapp.com/send?phone=${clientDetails.rop.phone || ''}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={b('whatsapp-link')}
                                    >
                                        <img src={Whatsapp} alt="Whatsapp" />
                                    </a>
                                </Tooltip>
                            </div>
                        </div>
                        :
                        <Text variant='body-2' className={b('emty-data')}>Нет данных</Text>
                }
            </div>
        </div>
    )
};

export default ContactCard;
