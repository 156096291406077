const mapper = new Map<string, string>([
    ['ABARTH', 'ABARTH'],
    ['ALFA ROMEO', 'Fiat / Alfa / Lancia'],
    ['AUDI', 'VAG'],
    ['BMW', 'BMW'],
    ['BMW MOTORRAD', 'BMW'],
    ['BUICK', 'General Motors'],
    ['CADILLAC', 'General Motors'],
    ['CHEVROLET', 'General Motors'],
    ['CHRYSLER', 'Chrysler'],
    ['CITROEN', 'Peugeot / Citroen'],
    ['DACIA', 'Renault'],
    ['DAEWOO', 'Daewoo'],
    ['DODGE', 'Chrysler'],
    ['DS', 'Peugeot / Citroen'],
    ['FIAT', 'Fiat / Alfa / Lancia'],
    ['FIAT PROFESSIONAL', 'Fiat / Alfa / Lancia'],
    ['FORD', 'Ford'],
    ['GMC', 'General Motors'],
    ['HOLDEN', 'General Motors'],
    ['HONDA', 'Honda'],
    ['HUMMER', 'General Motors'],
    ['HYUNDAI', 'Hyundai / KIA'],
    ['INFINITI', 'Nissan'],
    ['ISUZU', 'Isuzu'],
    ['JAGUAR', 'Jaguar'],
    ['JEEP', 'Chrysler'],
    ['KIA', 'Hyundai / KIA'],
    ['LANCIA', 'Fiat / Alfa / Lancia'],
    ['LAND ROVER', 'Land Rover'],
    ['LEXUS', 'Toyota'],
    ['MAZDA', 'Mazda'],
    ['MERCEDES-BENZ', 'Daimler AG'],
    ['MINI', 'BMW'],
    ['MITSUBISHI', 'Mitsubishi'],
    ['NISSAN', 'Nissan'],
    ['OLDSMOBILE', 'General Motors'],
    ['OPEL', 'General Motors'],
    ['PEUGEOT', 'Peugeot / Citroen'],
    ['PONTIAC', 'General Motors'],
    ['PORSCHE', 'Porsche'],
    ['RAM', 'RAM'],
    ['RAVON', 'RAVON'],
    ['RENAULT', 'Renault'],
    ['ROLLS-ROYCE', 'ROLLS-ROYCE'],
    ['SAAB', 'General Motors'],
    ['SATURN', 'General Motors'],
    ['SEAT', 'VAG'],
    ['SKODA', 'VAG'],
    ['SMART', 'Smart'],
    ['SSANGYONG', 'SsangYong'],
    ['SUBARU', 'Subaru'],
    ['SUZUKI', 'Suzuki'],
    ['TOYOTA', 'Toyota'],
    ['VAUXHALL', 'General Motors'],
    ['VOLKSWAGEN', 'VAG'],
    ['VOLVO', 'Volvo'],
    ['ZAZ', 'ЗАЗ'],
]);

export const getBrandNameByLaximoBrand =
    (laximoBrand: string): string | undefined => {
        return mapper.get(laximoBrand);
    };