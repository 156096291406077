import React, {useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {AppContext} from 'app/context';
import {Navigate, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {Alert, Link, Modal, Text, TextInput, useToaster} from '@gravity-ui/uikit';
import {Button} from '@components/shared/button';
import {CApi} from '@services/api';
import {Xmark} from '@gravity-ui/icons';
import {refreshBasketStorage} from 'app/shouldRefreshBasketStorage';

import './Login.scss';

const b = block('login-page');

interface LoginForm {
    login: string;
    password: string;
}

export const Login: React.FC = () => {
    const appCtx = useContext(AppContext);

    if (appCtx.state.token) {
        refreshBasketStorage.set(true);
        return <Navigate to="/home" />;
    }

    const [loading, setLoading] = useState(false);
    const navigateTo = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors},
        getValues,
        setError
    } = useForm<LoginForm>({defaultValues: {login: '', password: ''}});

    const [showToast, setShowToast] = useState(false);

    const onSubmit = () => {
        appCtx.emit('increment', {name: 'login_attempt'});

        setLoading(true);
        CApi().login.loginCreate(
            {
                ...getValues(),
                ...{type: 'password'}
            }
        ).then((r) => {
            setLoading(false);
            appCtx.emit('clear_cnt', {name: 'login_attempt'});
            appCtx.emit('login', {token: r.data.access_token});
            refreshBasketStorage.set(true);
        }).catch(() => {
            if (appCtx.state.counters['login_attempt'] >= 5) {
                setShowToast(true);
            }
            setLoading(false);
            setError('login', {message: 'Неправильный логин или пароль'});
            setError('password', {message: 'Неправильный логин или пароль'});
        });
    };

    const {add} = useToaster();
    useEffect(() => {
        if (showToast && appCtx.state.counters['login_attempt'] >= 5) {
            add({
                name: 'login_attempt',
                title: 'Вы забыли пароль?',
                theme: 'info',
                content: (
                    <>
                        <Text variant="body-2">
                            Вы более 5 раз ввели неверные данные. Можно восстановить пароль.
                        </Text>
                        <Button size="xl" onClick={() => navigateTo('/remind-pass')} design="white">
                            Да, восстановить пароль
                        </Button>
                    </>
                ),
                autoHiding: false,
                isClosable: true
            });
        }
    }, [showToast]);

    return (
        <Modal open keepMounted className={b()}>
            <Xmark
                width="28"
                height="28"
                className={b('exit')}
                onClick={() => navigateTo('/')}
            />
            <Text variant="header-2">
                Вход в Личный кабинет
            </Text>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className={b('input')}>
                    <Text variant="body-2">Логин</Text>
                    <TextInput
                        size="xl"
                        {...register('login', {
                            required: 'Обязательно'
                        })}
                        errorMessage={errors.login && errors.login.message}
                        errorPlacement="outside"
                        validationState={errors.login && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Text variant="body-2">Пароль</Text>
                    <TextInput
                        type="password"
                        size="xl"
                        {...register('password', {
                            required: 'Обязательно'
                        })}
                        errorMessage={errors.password && errors.password.message}
                        errorPlacement="outside"
                        validationState={errors.password && 'invalid'}
                    />
                </div>
                <div className={b('input')}>
                    <Button type="submit" size="xl" loading={loading}>Войти</Button>
                </div>
                <div className={b('input')}>
                    <Link view="normal" href="#" onClick={() => navigateTo('/remind-pass')}>Забыли пароль?</Link>
                </div>
                <div className={b('input')}>
                    <Alert
                        theme="info" title="Регистрация аккаунта" message={
                            <>Свяжитесь с нашими менеджерами: <br />+7(383)375-16-62 или avtoem@zapnsk.ru</>
                        }
                    />
                </div>
            </form>
        </Modal>
    );
};
