import block from 'bem-cn-lite';
import {Button, Text} from '@gravity-ui/uikit';
import {ListItems} from './DndContainer/components';
import {useContext, useEffect, useState} from 'react';
import {AppContext} from 'app/context';

import './PopupSettingsContent.scss';

const b = block('orders-popup-settings-content');

interface PopupSettingsContentProps {
    onClose: () => void;
}

export const PopupSettingsContent: React.FC<PopupSettingsContentProps> = ({onClose}) => {
    const {state, emit} = useContext(AppContext);
    const [isSaved, setIsSaved] = useState(false);
    const [isUserAction, setIsUserAction] = useState(true);

    const defaultData = [
        {id: 1, text: 'Дата заказа'},
        {id: 2, text: 'Дата доставки'},
        {id: 3, text: 'Статус'},
        {id: 4, text: 'Реф №'},
        {id: 5, text: 'Офиц. дилер / Возвратность'},
        {id: 6, text: 'Наименование'},
        {id: 7, text: 'Количество'},
        {id: 8, text: 'Цена'},
        {id: 9, text: 'Сумма заказа'},
        {id: 10, text: 'Комментарий'}
    ];
    const [data, setData] = useState(defaultData);

    const defaultItemsChecked = defaultData.reduce(
        (acc, item) => {
            acc[item.id] = true;
            return acc;
        },
        {} as Record<number, boolean>
    );
    const [itemsChecked, setItemsChecked] = useState<Record<number, boolean>>(defaultItemsChecked);

    useEffect(() => {
        // Загружаем конфиг из контекста
        if (state.orders.config) {
            const itemsOrder = state.orders.config.itemsOrder;
            const itemsChecked = state.orders.config.itemsChecked;

            const newData = itemsOrder.map((id) => defaultData.find((item) => item.id === id)!);
            setData(newData);
            setItemsChecked(itemsChecked);
        }
    }, [state.orders.config]);

    const handleSave = () => {
        const itemsOrder = data.map((item) => item.id);
        emit('updateOrdersConfig', {
            config: {
                itemsOrder,
                itemsChecked
            },
            isSavedConfig: true
        });
        setIsSaved(true);
        setIsUserAction(false);
    };

    const handleReset = () => {
        setData(defaultData);
        setItemsChecked(defaultItemsChecked);
        emit('updateOrdersConfig', {
            config: {
                itemsOrder: defaultData.map((item) => item.id),
                itemsChecked: defaultItemsChecked
            },
            isSavedConfig: true
        });

        setIsSaved(false);
    };

    const handleApply = () => {
        const itemsOrder = data.map((item) => item.id);
        emit('updateOrdersConfig', {
            config: {
                itemsOrder,
                itemsChecked
            }
        });
        onClose();
    };

    useEffect(() => {
        if (isUserAction) {
            setIsSaved(false);
        }

        setIsUserAction(true);
    }, [data, itemsChecked]);

    return (
        <div className={b()}>
            <div className={b('header-content')}>
                <Text variant="subheader-2">Настройка столбцов</Text>
                <Button
                    size="m"
                    className={`${b('save-button')} ${isSaved ? `${b('save-button')}--saved` : ''}`}
                    onClick={handleSave}
                >
                    {isSaved ? 'Сохранено' : 'Сохранить'}
                </Button>
            </div>

            <div className={b('content')}>
                <ListItems
                    data={data}
                    setData={setData}
                    itemsChecked={itemsChecked}
                    setItemsChecked={setItemsChecked}
                />
            </div>

            <div className={b('footer-content')}>
                <Button size="l" className={b('reset-button')} onClick={handleReset}>
                    Сбросить все
                </Button>
                <Button size="l" view="action" className={b('apply-button')} onClick={handleApply}>
                    Применить
                </Button>
            </div>
        </div>
    );
};
