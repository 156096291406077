import {useMemo} from 'react';
import {defaultDirection, sortItems} from '../utils/sortUtils';
import {useBasket} from './useBasket';

export const useSortedItems = (
    sortOption: {property: string; direction: 'asc' | 'desc'} | null,
    sortPriority: string[]
) => {
    const {items, itemsOut} = useBasket();

    const allItems = useMemo(() => {
        const mappedItems = (items || []).map((item) => ({
            ...item,
            source: 'items',
            isOfficialDealer: !!item.flags?.official,
            isReturnPossible: item.returnCondition !== 'impossible'
        }));

        const mappedItemsOut = (itemsOut || []).map((item) => ({
            ...item,
            source: 'itemsOut',
            isOfficialDealer: !!item.flags?.official,
            isReturnPossible: item.returnCondition !== 'impossible'
        }));

        return [...mappedItems, ...mappedItemsOut];
    }, [items, itemsOut]);

    const currentSortPriority = useMemo(() => {
        if (sortOption) {
            return [
                sortOption.property,
                ...sortPriority.filter((item) => item !== sortOption.property)
            ];
        } else {
            return ['date', ...sortPriority];
        }
    }, [sortOption, sortPriority]);

    const sortedItems = useMemo(() => {
        return sortItems(allItems, currentSortPriority, sortOption, defaultDirection);
    }, [allItems, currentSortPriority, sortOption]);

    return sortedItems;
};
