import React, {useEffect, useMemo, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {Loader, Text} from '@gravity-ui/uikit';
import {OrdersEmpty, OrdersHeaderControls, OrdersItems} from '@features/orders';
import {useOrders} from '@features/orders/hooks';
import {ScrollUpButton} from '@components';
import {defaultDirection} from '@features/orders/utils/sortUtils';
import StickyBox from 'react-sticky-box';
import {useMediaQuery} from 'react-responsive';

import './OrdersPage.scss';

const b = block('orders-page');

const stickyHeaderControlsTopOffset = 127;

export const OrdersPage = () => {
    const [hiddenScrollBtn, setHiddenScrollBtn] = useState<boolean>(true);
    const isScreenSmall = useMediaQuery({query: '(max-width: 1440px)'});
    const [stickyOffset, setStickyOffset] = useState(isScreenSmall ? 363 : 320);
    const [sortOption, setSortOption] = useState<{
        property: string;
        direction: 'asc' | 'desc';
    } | null>(null);
    const [showSentinel, setShowSentinel] = useState(false);

    const {items, filters, setFilters, isLoading, hasMore, loadMoreItems} = useOrders({sortOption});

    const sentinelRef = useRef<HTMLDivElement>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const hasInitialLoad = items.length > 0;

    const toggleStickyOffset = (newOffset: number) => {
        setStickyOffset(newOffset);
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null;

        if (hasInitialLoad && hasMore && !isLoading) {
            timer = setTimeout(() => {
                setShowSentinel(true);
            }, 500);
        } else {
            setShowSentinel(false);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [hasInitialLoad, hasMore, isLoading]);

    // Настройка IntersectionObserver
    useEffect(() => {
        if (!showSentinel) return;
        if (!sentinelRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    if (observerRef.current && sentinelRef.current) {
                        observerRef.current.unobserve(sentinelRef.current);
                    }
                    loadMoreItems();
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
            }
        );

        observer.observe(sentinelRef.current);
        observerRef.current = observer;

        return () => {
            if (observerRef.current && sentinelRef.current) {
                observerRef.current.unobserve(sentinelRef.current);
            }
        };
    }, [showSentinel, loadMoreItems]);

    useEffect(() => {
        if (
            !isLoading &&
            hasInitialLoad &&
            hasMore &&
            showSentinel &&
            sentinelRef.current &&
            observerRef.current
        ) {
            observerRef.current.observe(sentinelRef.current);
        }
    }, [isLoading, hasInitialLoad, hasMore, showSentinel]);

    const handleSortChange = (field: string) => {
        setSortOption((prevSortOption) => {
            if (prevSortOption && prevSortOption.property === field) {
                return {
                    property: field,
                    direction: prevSortOption.direction === 'asc' ? 'desc' : 'asc'
                };
            } else {
                const direction = defaultDirection.get(field) === 1 ? 'asc' : 'desc';
                return {
                    property: field,
                    direction: direction as 'asc' | 'desc'
                };
            }
        });

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const isFiltered = useMemo(() => {
        const hasCreatedAtRange =
            filters.createdAtRange && (filters.createdAtRange.start || filters.createdAtRange.end);
        const hasDeliveryDateRange =
            filters.deliveryDateRange &&
            (filters.deliveryDateRange.start || filters.deliveryDateRange.end);
        const hasSelectedBrands = filters.brands.some((brand) => brand.isSelected);
        const hasSelectedStatuses = filters.statuses.some((status) => status.isSelected);
        const hasSelectedRefs = filters.refs.some((ref) => ref.isSelected);
        const hasSelectedArticles = filters.articles.some((article) => article.isSelected);
        const hasPriceFilter = filters.price && (filters.price.from || filters.price.to);
        const hasSumFilter = filters.sum && (filters.sum.from || filters.sum.to);
        const hasOnlyWithComments = filters.onlyWithComments;

        return (
            hasCreatedAtRange ||
            hasDeliveryDateRange ||
            hasSelectedBrands ||
            hasSelectedStatuses ||
            hasSelectedRefs ||
            hasSelectedArticles ||
            hasPriceFilter ||
            hasSumFilter ||
            hasOnlyWithComments
        );
    }, [filters]);

    // Заказов нет, фильтры не применены и загрузка завершена
    if (!isLoading && items.length === 0 && !isFiltered) {
        return (
            <div className={b()}>
                <Text className={b("no-orders-header")} variant={'header-2'}>Заказы</Text>
                <OrdersEmpty isNoOrders={true} />
            </div>
        );
    }

    return (
        <div className={b()}>
            <StickyBox
                className={b('orders-header-controls-sticky-box')}
                offsetTop={stickyHeaderControlsTopOffset}
            >
                <OrdersHeaderControls
                    filters={filters}
                    setFilters={setFilters}
                    setStickyOffset={toggleStickyOffset}
                />
            </StickyBox>

            {isLoading && items.length === 0 ? (
                <div className={b('loader-new-orders')}>
                    <Loader size="l" />
                </div>
            ) : items.length === 0 ? (
                <OrdersEmpty isNoOrders={!isFiltered} />
            ) : (
                <>
                    <OrdersItems
                        items={items}
                        onSortChange={handleSortChange}
                        sortOption={sortOption}
                        stickyOffset={stickyOffset}
                    />
                    {isLoading && (
                        <div className={b('loader-new-orders')}>
                            <Loader size="l" />
                        </div>
                    )}
                </>
            )}

            {showSentinel && <div ref={sentinelRef} style={{height: '1px'}} />}
            
            {!hasMore && !isLoading && items.length > 0 && (
                <Text className={b('loader-new-orders')}>Все заказы загружены.</Text>
            )}

            <ScrollUpButton hiddenBtn={hiddenScrollBtn} changeVisibility={setHiddenScrollBtn} />
        </div>
    );
};
