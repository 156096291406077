import {CApi as userGwApi} from '@services/api';
import {getLocalDateTime} from '@utils';
import {ProposalModel} from '@features/basket/interfaces/ProposalModel';
import {basketStorage} from '@app/basketStorage';
import {BasketItemModel} from '@features/basket/interfaces';

export const getBasket = () => {

    return userGwApi()
        .basket.basketList()
        .then((r) => {
            console.log('Данные получены:', r);

            const basketData = r.data.items.map((x) => {
                return {
                    article: x.article,
                    articleName: x.articleName,
                    basketItemId: x.basketItemId,
                    brand: x.brand,
                    brandName: x.brandName,
                    comment: x.comment,
                    createdAt: getLocalDateTime(x.createdAt),
                    deliveryDate: getLocalDateTime(x.delivery?.expected.client.date),
                    deliveryDays: x.delivery?.expected.client.days,
                    description: x.description,
                    expiresAt: getLocalDateTime(x.expiresAt),
                    flags: x.flags,
                    id: x.id,
                    isAnalog: x.isAnalog,
                    itemsCnt: x.itemsCnt,
                    multiplicity: x.multiplicity,
                    price: x.price,
                    quantity: x.quantity,
                    rating: x.rating,
                    returnCondition: x.returnCondition,
                    status: x.status
                } as ProposalModel;
            });

            const savedBasket = basketStorage.get() || {items: [], itemsOut: []};

            const items: Array<BasketItemModel> = [];
            const itemsOut: Array<BasketItemModel> = [];

            basketData.forEach((item) => {
                const savedItem =
                    savedBasket.items.find((saved) => saved.id === item.id) ||
                    savedBasket.itemsOut.find((savedOut) => savedOut.id === item.id);

                // Если элемент сохранён, используем его состояние checked, иначе устанавливаем true по умолчанию
                const isChecked = savedItem?.checked ?? true;
                const amount = item.price * item.itemsCnt;

                const newItem = {
                    ...item,
                    checked: isChecked,
                    amount: amount,
                    newPrice: undefined // Изначально нет новой цены
                } as unknown as BasketItemModel;

                // Распределяем товары по доступности
                if (item.status === 'unavailable') {
                    itemsOut.push(newItem);
                } else {
                    items.push(newItem);
                }
            });

            return Promise.resolve({items, itemsOut});
        });
};