import {AppState} from './context';

const ORDERS_SETTINGS_KEY = 'ordersSettings';

class OrdersSettingsStorage {
    set = (config: AppState['orders']['config']) =>
        localStorage.setItem(ORDERS_SETTINGS_KEY, JSON.stringify(config));

    get = (): AppState['orders']['config'] | null => {
        const storedConfig = localStorage.getItem(ORDERS_SETTINGS_KEY);
        return storedConfig ? JSON.parse(storedConfig) : null;
    };

    clear = () => localStorage.removeItem(ORDERS_SETTINGS_KEY);
}

export const ordersSettingsStorage = new OrdersSettingsStorage();
