import React, {useContext, useEffect, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {ChevronDown} from '@gravity-ui/icons';
import './SectionHeader.scss';
import {Alert, Button, Progress, Text} from '@gravity-ui/uikit';
import {SearchContext} from '@features/search/context';
import {AsyncSearchState} from '@features/search/interfaces';

const b = block('section-header');

export interface SectionHeaderProps {
    title: string;
    isHidden?: boolean;
    onToggleVisibility?: () => void;
    isTopLevel: boolean;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
    isHidden,
    onToggleVisibility,
    title,
    isTopLevel
}) => {
    const searchContext = useContext(SearchContext);
    const [showAlert, setShowAlert] = useState(false);
    const progressBarRef = useRef(null);
    const prevSearchState = useRef<AsyncSearchState>(searchContext.state);

    function handleClick() {
        onToggleVisibility?.();
    }

    useEffect(() => {
        // Убираем атрибут style, чтобы использовать ccs-анимацию для прогресс-бара
        if (progressBarRef.current) { // @ts-ignore
            progressBarRef.current.removeAttribute('style');
        }
    }, []);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout> | null = null;

        // Если предыдущее состояние было Searching, а текущее None — показываем алерт
        if (
            prevSearchState.current === AsyncSearchState.Searching &&
            searchContext.state === AsyncSearchState.None
        ) {
            setShowAlert(true);

            timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000);
        }

        // Обновляем предыдущее состояние на текущее
        prevSearchState.current = searchContext.state;

        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [searchContext.state]);

    return (
        <div className={b()}>
            {title}
            <ChevronDown
                className={b('icon', {rotation: isHidden})}
                onClick={handleClick}
            />
            {isTopLevel && (searchContext.state === AsyncSearchState.Searching) ?
                <div className={b('progress')}>
                    <Progress ref={progressBarRef} size={'s'} value={0} loading={true} theme={'warning'}></Progress>
                </div>
                : null}

            {showAlert && (
                <Alert
                    theme="info"
                    className={b('alert')}
                    message={
                        <Text variant="body-1" color="complementary">
                            Поиск по всем поставщикам завершен
                        </Text>
                    }
                />
            )}
        </div>
    );
};
