import './BasketPage.scss';
import React, {useContext, useEffect, useRef, useState} from 'react';
import block from 'bem-cn-lite';
import {Loader, Text} from '@gravity-ui/uikit';
import {BasketError, BasketHeaderControls, BasketItems, BasketSummary, CheckAvailableLoading} from '@features/basket';
import {BasketEmpty} from '@features/basket/components/BasketEmpty/BasketEmpty';
import {BasketItemsOut} from '@features/basket/components/BasketItemsOut';
import {AppContext} from 'app/context';
import {defaultDirection, defaultSortPriority} from '@features/basket/utils/sortUtils';
import {useSortedItems} from '@features/basket/hooks/useSortedItems';
import useIsTabActive from '@hooks/useIsTabActive';
import {refreshBasketStorage} from 'app/shouldRefreshBasketStorage';
import {useCheckBasket} from '@features/basket/hooks/useCheckBasket';
import {getBasket, getBasketForStateUpdate} from '@features/basket/services';

const b = block('basket-page');

export const BasketPage: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const {items, itemsOut} = state.basket;

    const isTabActive = useIsTabActive();
    const isFirstRef = useRef(true);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [sortPriority, setSortPriority] = useState<string[]>(defaultSortPriority);
    const [sortOption, setSortOption] = useState<{
        property: string;
        direction: 'asc' | 'desc';
    } | null>(null);

    const {checkBasket, isLoading: isCheckLoading, loadingText} = useCheckBasket();
    const [isModalOpenCheckLoading, setIsModalOpenCheckLoading] = useState(false);
    const [isOrderCreated, setIsOrderCreated] = useState(false);

    const loadBasket = () => {
        getBasket()
            .then(({items, itemsOut}) => {
                emit('updateBasket', {
                    basket: {
                        items,
                        itemsOut
                    }
                });

                const shouldRefresh = refreshBasketStorage.get()
                    && (items.length > 0 || itemsOut.length > 0);

                if (shouldRefresh) {
                    setIsModalOpenCheckLoading(true);
                    checkBasket()
                        .then(() => refreshBasketStorage.set(false))
                        .finally(() => setIsModalOpenCheckLoading(false));
                } else {
                    refreshBasketStorage.set(false);
                }
            })
            .catch((err) => {
                console.error('Ошибка при загрузке корзины:', err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const handleBeforeUnload = () => {
            setIsOrderCreated(false);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            handleBeforeUnload();
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        loadBasket();
    }, []);

    useEffect(() => {
        if (!isTabActive) return;

        if (isFirstRef.current) {
            isFirstRef.current = false;
            return;
        }

        loadBasket();
    }, [isTabActive]);

    useEffect(() => {
        const basketState = getBasketForStateUpdate(items, itemsOut);
        emit('updateBasket', basketState);
    }, [items, itemsOut]);

    /*Разделение на доступные и недоступные после обновление, для отображения разных текстов ошибки*/
    const allItems = [...items, ...itemsOut];

    const itemsUnavailableOccurred = allItems.some((item) => item.quantityChangedToZero);
    const itemsCntExceedsQuantityOccurred = allItems.some((item) => item.quantityDecreased);
    /*Разделение на доступные и недоступные после обновление, для отображения разных текстов ошибки*/

    const handleSortChange = (field: string) => {
        setSortOption((prevSortOption) => {
            if (prevSortOption && prevSortOption.property === field) {
                return {
                    property: field,
                    direction: prevSortOption.direction === 'asc' ? 'desc' : 'asc'
                };
            } else {
                const direction = defaultDirection.get(field) === 1 ? 'asc' : 'desc';
                return {
                    property: field,
                    direction: direction as 'asc' | 'desc'
                };
            }
        });

        setSortPriority((prevSortPriority) => {
            const newSortPriority = prevSortPriority.filter((item) => item !== field);
            newSortPriority.unshift(field);
            return newSortPriority;
        });
    };

    const sortedItems = useSortedItems(sortOption, sortPriority);

    if (isLoading) {
        return (
            <div className={b('loader')}>
                <Loader size="l" />
            </div>
        );
    }

    if (error) {
        return (
            <div className={b()}>
                <Text variant={'header-2'}>Корзина</Text>
                <BasketError />
            </div>
        );
    }

    if (items.length === 0 && itemsOut.length === 0 && !isOrderCreated) {
        return (
            <div className={b()}>
                <Text variant={'header-2'}>Корзина</Text>
                <BasketEmpty />
            </div>
        );
    }

    return (
        <div className={b()}>
            <Text variant={'header-2'}>Корзина</Text>
            <BasketHeaderControls />
            <BasketItems
                items={sortedItems.filter((item) => item.source === 'items')}
                onSortChange={handleSortChange}
                sortOption={sortOption}
            />
            <BasketSummary
                onOrderCreated={(isCreated: boolean) => setIsOrderCreated(isCreated)}
                onError={setError}
            />

            {itemsOut.length > 0 && (
                <BasketItemsOut
                    itemsUnavailableOccurred={itemsUnavailableOccurred}
                    itemsCntExceedsQuantityOccurred={itemsCntExceedsQuantityOccurred}
                    items={sortedItems.filter((item) => item.source === 'itemsOut')}
                />
            )}

            <CheckAvailableLoading
                open={isModalOpenCheckLoading && isCheckLoading}
                text={loadingText}
                onClose={() => setIsModalOpenCheckLoading(false)}
            />
        </div>
    );
};
