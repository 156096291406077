import React, {useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Loader, Text} from '@gravity-ui/uikit';
import {
    BalanceError,
    CreditLimitCard,
    CurrentBalanceCard,
    RetailBalanceCard
} from '@features/balance';
import {AppContext} from 'app/context';
import {CApi as userGwApi} from '@services/api';
import {BalanceData} from '@features/balance/interfaces/BalanceData';
import {transformBalanceData} from '@features/balance/utils/transformBalanceData';
import {useToastBalance} from '@features/balance/hooks/useToastBalance';

import './BalancePage.scss';

const b = block('balance-page');

export const BalancePage = () => {
    const {state} = useContext(AppContext);
    const [balanceData, setBalanceData] = useState<BalanceData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const {showBlockToast, showWarningToast} = useToastBalance();

    const balanceRestrictionType = state.clientDetails?.balance.balanceRestrictionType;

    useEffect(() => {
        if (state?.user?.login) {
            userGwApi()
                .client.balanceDetail(state.user.client)
                .then((response) => {
                    console.log('response', response);

                    if (response.data) {
                        const processedData = transformBalanceData(response.data);
                        setBalanceData(processedData);

                        // Обработка уведомлений в зависимости от deniedFlags
                        const deniedFlags = response.data.deniedFlags || [];

                        if (deniedFlags.includes('order') && deniedFlags.includes('shipment')) {
                            showBlockToast(true); // Заблокировано для заказов и отгрузки
                        } else if (deniedFlags.includes('order')) {
                            showBlockToast(false); // Заблокировано только для заказов
                        } else if (deniedFlags.includes('shipment')) {
                            showWarningToast(); // Предупреждение для отгрузки
                        }
                    } else {
                        setHasError(true);
                    }
                })
                .catch((error) => {
                    console.error('Не удалось получить данные баланса:', error);
                    setHasError(true);
                })
                .finally(() => setIsLoading(false));
        } else {
            setHasError(true);
            setIsLoading(false);
        }
    }, [state?.user?.login]);

    if (isLoading) {
        return (
            <div className={b('loader')}>
                <Loader size="l" />
            </div>
        );
    }

    if (hasError || !balanceData) {
        return (
            <div className={b()}>
                <Text variant="display-2" className={b('header')}>
                    Баланс
                </Text>
                <BalanceError />
            </div>
        );
    }

    return (
        <div className={b()}>
            <Text variant="display-2" className={b('header')}>
                Баланс
            </Text>

            <div className={b('content')}>
                {balanceData && (
                    <>
                        <CurrentBalanceCard balanceData={balanceData} />
                        {balanceRestrictionType &&
                            ['post_payment', 'prepaid_shipment', 'prepaid_receiving'].includes(
                                balanceRestrictionType
                            ) && <CreditLimitCard balanceData={balanceData} />}

                        {balanceRestrictionType === 'retail' && (
                            <RetailBalanceCard balanceData={balanceData} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
