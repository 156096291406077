import {useContext} from 'react';
import block from 'bem-cn-lite';
import {Text, Icon} from '@gravity-ui/uikit';
import {Clock, MapPin} from '@gravity-ui/icons';
import {AppContext} from '@app/context';

import './StoreAddress.scss';

const b = block('store-address');

export const StoreAddress: React.FC = () => {
    const {state: {clientDetails}} = useContext(AppContext);

    return (<>
        <div className={b()}>
            <Text variant='header-1'>Склад</Text>
            <Text className={b('address')} variant='body-2'>
                <Icon className={b('icon')} data={MapPin} />{clientDetails?.warehouse.address || ''}</Text>
            <div className={b('worktime-container')}>
                <Icon className={b('icon')} data={Clock}/>
                <div className={b('worktime-content')}>
                    {clientDetails?.warehouse.workingHours ?
                        clientDetails?.warehouse.workingHours.map((item, index) => {
                            return <div className={b('content')} key={index}>
                                <Text className={b('content-day')} variant='body-2' >{item.slice(0, 4)}</Text>
                                <Text className={b('content-time')} variant='body-2' >{item.slice(4, 9)}</Text>
                                <Text className={b('content-time')} variant='body-2' >{item.slice(10, item.length)}</Text>
                            </div>
                        })
                        : null
                    }
                </div>
            </div>
        </div>
    </>
    );
};
