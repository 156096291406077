export function cleanSearchValue(value: string | null): string {
    if (!value)
        return '';

    return value
        .replace(
            /[^a-zA-Z0-9аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+/g,
            ''
        )
        .toUpperCase();
}
