import {BasketItemModel} from '@features/basket/interfaces';

export const getBasketTotals = (items: BasketItemModel[]) => {

    // Рассчитываем общее количество и сумму для отмеченных товаров
    const totalQuantity = items.reduce(
        (prev, curr) => (curr.checked ? prev + curr.itemsCnt : prev),
        0
    );
    const totalAmount = items.reduce(
        (prev, curr) => (curr.checked ? prev + curr.amount : prev),
        0
    );

    return {totalAmount, totalQuantity};
}