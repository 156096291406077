import {BasketItemModel} from '@features/basket/interfaces';
import {getBasketChecked, getBasketSelectedCount, getBasketTotals} from '.';

export const getBasketForStateUpdate = (items: BasketItemModel[], itemsOut: BasketItemModel[]) => {
    // Рассчитываем общее количество и сумму для отмеченных товаров
    const {totalQuantity, totalAmount} = getBasketTotals(items);

    // Проверяем, все ли товары отмечены
    const {allChecked, allCheckedItemsOut} = getBasketChecked(items, itemsOut);

    // Количество выбранных элементов
    const {selectedCount} = getBasketSelectedCount(items);

    return {
        basket: {
            summary: {totalAmount, totalQuantity},
            allChecked,
            allCheckedItemsOut,
            selectedCount
        }
    };
};