import './Header.scss';
import React, {useContext, useEffect, useState} from 'react';
import block from 'bem-cn-lite';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {
    Breadcrumbs,
    Button,
    FirstDisplayedItemsCount,
    Icon,
    LastDisplayedItemsCount,
    Popup,
    Text,
    User
} from '@gravity-ui/uikit';
import {Box, CircleRuble, Handset, House, MapPin, ShoppingCart} from '@gravity-ui/icons';
import LogoPng from '@assets/logo.png';
import ContactCard from './components/ContactCard/ContactCard';
import UserCard from './components/UserCard/UserCard';
import useHoverCard from '@hooks/useHoverCardHeader';
import {AppContext} from 'app/context';
import {SearchInput} from './components/SearchInput/SearchInput';
import {usePopoverAuxControls} from '@hooks';
import {PopupClientsAddress} from './components/PopupClientsAddress/PopupClientsAddress';
import {BalanceElement} from './components/BalanceElement/BalanceElement';
import {isBasketLocation} from '@utils';

const b = block('header');

export const Header: React.FC = () => {
    const {state, emit} = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();
    const {open, buttonRef, toggleOpen, closePopover} = usePopoverAuxControls();
    const {user} = state;

    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {
            text: 'Главная',
            action: () => {
                emit('toggleBrandSearch', {active: false}); // Убираем поиск при клике на "Главная"
                navigate('/home');
            }
        }
    ]);

    useEffect(() => {
        if (
            location.pathname === '/basket' ||
            location.pathname === '/search' ||
            location.pathname === '/profile' ||
            location.pathname === '/orders' ||
            location.pathname === '/balance'
        ) {
            if (state.isBrandSearchActive) {
                emit('toggleBrandSearch', {active: false});
            }
        }

        const newItems = [
            {
                text: 'Главная',
                action: () => {
                    emit('toggleBrandSearch', {active: false});
                    navigate('/home');
                }
            }
        ];

        // Добавляем элемент "Корзина", если находимся на странице корзины
        if (isBasketLocation()) {
            if (!newItems.some((item) => item.text === 'Корзина')) {
                newItems.push({
                    text: 'Корзина',
                    action: () => {
                        emit('toggleBrandSearch', {active: false});
                        navigate('/basket');
                    }
                });
            }
        }

        // Добавляем элемент "Профиль", если находимся на странице профиль
        if (location.pathname.includes('/profile')) {
            newItems.push({
                text: 'Профиль',
                action: () => {
                    emit('toggleBrandSearch', {active: false});
                    navigate('/profile');
                }
            });
        }

        if (location.pathname === '/search') {
            if (!newItems.some((item) => item.text === 'Поиск запчасти по коду')) {
                newItems.push({
                    text: 'Поиск запчасти по коду',
                    action: () => {
                        emit('toggleBrandSearch', {active: false});
                        navigate('/search');
                    }
                });
            }
        }

        if (location.pathname === '/orders') {
            if (!newItems.some((item) => item.text === 'Заказы')) {
                newItems.push({
                    text: 'Заказы',
                    action: () => {
                        emit('toggleBrandSearch', {active: false});
                        navigate('/orders');
                    }
                });
            }
        }

        if (location.pathname === '/balance') {
            if (!newItems.some((item) => item.text === 'Баланс')) {
                newItems.push({
                    text: 'Баланс',
                    action: () => {
                        emit('toggleBrandSearch', {active: false});
                        navigate('/balance');
                    }
                });
            }
        }

        // Добавляем элемент "Уточнение запроса", если активен поиск бренда
        if (state.isBrandSearchActive) {
            newItems.push({
                text: 'Уточнение запроса',
                action: () => {}
            });
        }

        setBreadcrumbItems(newItems);
    }, [location, navigate, state.isBrandSearchActive]);

    // Хук для кнопки и ContactCard
    const {
        isVisible: isContactCardVisible,
        handleMouseEnter: handleMouseEnterContact,
        handleMouseLeave: handleMouseLeaveContact
    } = useHoverCard();

    // Хук для User и UserCard
    const {
        isVisible: isUserCardVisible,
        handleMouseEnter: handleMouseEnterUser,
        handleMouseLeave: handleMouseLeaveUser,
        hideUserCard
    } = useHoverCard();

    // Получаем количество элементов в корзине (кроме недоступных)
    const basketItemCount =
        state.basket.items.filter((item) => item.status === 'actual' || item.status === 'stale')
            .length || 0;

    const displayCount = basketItemCount > 99 ? `${String(basketItemCount)[0]}..` : basketItemCount;

    return (
        <header className={b()}>
            <div className={b('header-top')}>
                <div className={b('left-section')}>
                    <Link to="/home">
                        <img src={LogoPng} className={b('header-logo')} alt="Авто-Транзит" />
                    </Link>

                    <Button
                        size="xl"
                        onClick={() => navigate('/home')}
                        className={b('home-button')}
                    >
                        <Icon data={House} width="20" height="20" />
                    </Button>

                    <div
                        onMouseEnter={handleMouseEnterContact}
                        onMouseLeave={handleMouseLeaveContact}
                    >
                        <Button size="xl" className={b('contact-button')}>
                            <Icon data={Handset} width="20" height="20" />
                        </Button>
                        {isContactCardVisible && <ContactCard />}
                    </div>

                    {location.pathname !== '/home' && location.pathname !== '/search' && (
                        <SearchInput />
                    )}
                </div>

                <div className={b('right-section')}>
                    <Button
                        size="xl"
                        className={b('adress-button')}
                        ref={buttonRef}
                        onClick={toggleOpen}
                    >
                        <Icon data={MapPin} width="20" height="20" className={b('adress-icon')} />
                    </Button>

                    <Popup
                        anchorRef={buttonRef}
                        onClose={closePopover}
                        open={open}
                        placement="bottom-start"
                    >
                        <PopupClientsAddress />
                    </Popup>

                    <Link to="/basket">
                        <Button size="xl" className={b('right-section-button')}>
                            <div className={b('cart-wrapper')}>
                                <ShoppingCart width="20" height="20" />
                                <span className={b('cart-count')}>{displayCount}</span>
                            </div>

                            <Text>Корзина</Text>
                        </Button>
                    </Link>

                    <Link to="/orders">
                        <Button size="xl" className={b('right-section-button')}>
                            <Box width="20" height="20" />

                            <Text>Заказы</Text>
                        </Button>
                    </Link>

                    <Link to="/balance">
                        <Button size="xl" className={b('right-section-button')}>
                            <CircleRuble width="20" height="20" />

                            <Text>Баланс</Text>
                        </Button>
                    </Link>

                    <div onMouseEnter={handleMouseEnterUser} onMouseLeave={handleMouseLeaveUser}>
                        <User
                            className={b('avatar')}
                            avatar={{text: user?.displayName, theme: 'brand'}}
                            name={user?.displayName}
                            description={user?.login}
                            size="l"
                        />
                        {isUserCardVisible && <UserCard onClose={hideUserCard} />}
                    </div>
                </div>
            </div>

            <div className={b('header-bottom')}>
                <nav aria-label="breadcrumb">
                    <Breadcrumbs
                        items={breadcrumbItems}
                        firstDisplayedItemsCount={FirstDisplayedItemsCount.One}
                        lastDisplayedItemsCount={LastDisplayedItemsCount.One}
                    />
                </nav>

                <BalanceElement />
            </div>
        </header>
    );
};
